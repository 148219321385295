<template>
    <main class="news-post">
        <AprRepresentative />

        <div class="max-w-screen-md mx-auto pt-24 px-8 lg:px-0">
            <template v-if="!loading && post !== null">
                <div class="navigation mb-16 font-bold uppercase">
                    <a :href="$router.resolve({name: 'news'}).href" title="Back to posts" class="inline-flex text-unity-red hover:text-unity-dark-grey">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-6 h-auto mr-4"><path fill="#EC7358" d="M107.515 150.971L8.485 250c-4.686 4.686-4.686 12.284 0 16.971L107.515 366c7.56 7.56 20.485 2.206 20.485-8.485v-71.03h308c6.627 0 12-5.373 12-12v-32c0-6.627-5.373-12-12-12H128v-71.03c0-10.69-12.926-16.044-20.485-8.484z"/></svg>
                        <span>Back to posts</span>
                    </a>
                </div>
                <h1 class="font-bold mb-8">{{ post.title }}</h1>
                <div class="post-date uppercase text-gray-500 font-bold mb-5">{{ format(new Date(post.date), 'do MMMM yyyy') }}</div>
                <div class="post-html text-black" v-html="post.body" />
            </template>
        </div>

        <CallBackForm />
    </main>
</template>

<script>
import {Cockpit} from "@/lib/Cockpit";
import {format} from "date-fns";
import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "NewsPost",

    components: {AprRepresentative, CallBackForm, Button},

    metaInfo() {
        return {
            title: this.postTitle
        }
    },

    data() {
        return {
            format,
            cockpit: new Cockpit(),
            loading: false,
            post: null,
            postTitle: null
        }
    },

    mounted() {
        this.getPost(this.$route.params.slug)
    },

    methods: {
        async getPost(slug) {
            this.loading = true

            const posts = await this.cockpit.getCollection('unity_blog_posts', {
                filter: {
                    slug: slug
                },
                limit: 1,
            })

            if(posts.length !== 1) {
                return this.$router.push({name: 'news'})
            }

            this.post = posts[0]
            this.post.body = this.resolveImages(this.post.body)

            // Update the banner title
            this.$emit('update-banner-title', {
                title: this.post.title,
                subTitle: this.post.subTitle
            })

            // Update page/meta title
            this.postTitle = this.post.title

            this.loading = false
        },

        resolveImages(body) {
            return body.replace(/\<img([^>]*)\ssrc=('|")([^>]*)\2\s([^>]*)\/\>/gi, `<img$1 src=$2${process.env.VUE_APP_COCKPIT_ASSET_BASE}$3$2 $4/>`);
        }
    }
}
</script>
<style lang="scss">
.post-html {
    p {
        margin-bottom: 30px !important;
        color: black !important;
        font-size: 1.3rem;
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight:bold;
        margin-top: 50px;
        margin-bottom: 20px;
    }
}
</style>
