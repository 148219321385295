<template>
    <main>
       <AprRepresentative />

        <div class="complaints max-w-screen-xl mx-auto py-20 px-8 sm:px-6 xxl:px-0">
            <div class="grid lg:grid-cols-2 lg:space-x-8">
                <div>
                    <p>This policy sets out the processes by which Unity Auto Finance Ltd demonstrate and
                        evidence their processes regarding complaints.</p>

                    <p>The document considers what steps all Unity Auto Finance Ltd are required to take to
                        ensure that:</p>

                    <ul class="list-disc list-outside ml-5 mb-5">
                        <li>Complaints will be identified and acknowledged; comprehensively, independently and
                            fairly investigated and where appropriate redress paid</li>
                        <li>Customers understand that they have the right to complain to the Financial
                        Ombudsman Service (FOS) if they are unsatisfied with the way their complaint has
                        been handled</li>
                        <li>Customers are kept informed</li>
                        <li>Appropriate staff will keep up to date with FOS determinations and redress awards</li>
                        <li>Complaints information will be recorded and reported to the FCA, emerging trends
                        will be analysed and remedial action taken</li>
                    </ul>

                    <p>The following areas are considered in this policy document:</p>

                    <ul class="list-disc list-outside ml-5 mb-5">
                        <li>What is a complaint</li>
                        <li>How and where to complain</li>
                        <li>Who handles complaints</li>
                        <li>How we respond to complaints</li>
                        <li>Summary Resolution Communication</li>
                        <li>Final Response Letter</li>
                        <li>The Financial Ombudsman Service</li>
                        <li>Recording complaints data</li>
                        <li>Complaints Recording Spreadsheet</li>
                        <li>Summary Resolution Communication template</li>
                        <li>Table of Policy Update/History</li>
                    </ul>

                    <h3>What is a complaint?</h3>

                    <p>The Financial Conduct Authority (FCA) defines a complaint as: 'Any expression of
                    dissatisfaction, whether oral or written, and whether justified or not, from or on behalf of an
                        eligible complainant about the firm's provision of, or failure to provide, a financial service'.</p>

                    <h3>How and where to complain</h3>

                    <p>A complaint does not have to be in writing, we accept complaints by telephone, email and
                        post.</p>

                    <p>In writing – address your letter to:</p>

                    <address>Compliance Department, Unity Auto Finance, Unity<br />
                    House, Bird Hall Lane, Cheadle SK3 0UX<br />
                    By telephone – 0330 912 1002<br />
                    By email – hello@unityauto.finance</address>

                    <h3>Who handles complaints?</h3>

                    <p>Complaints received are immediately passed to the compliance department.</p>

                    <h3>How we respond to complaints</h3>

                    <p>We aim to resolve your complaint within three business days following receipt of the
                        complaint.</p>
                </div>
                <div>
                    <p>If this is not possible, we will send an acknowledgment letter to you (within five business
                        days) and advise when a full response can be expected.</p>

                    <p>This letter will confirm that we have received the complaint and it is being dealt with. It will
                        include the name and contact details of the person dealing with the complaint.</p>

                    <h3>Summary Resolution Communication</h3>

                    <p>If the complaint is resolved within three business days we will issue a summary resolution
                    communication. This will acknowledge the complaint and inform the complainant that the
                    respondent considers the complaint to be resolved. The communication will provide the
                    complainant with the details of the Financial Ombudsman Service (FOS) confirming that they
                        can refer the complaint to them if they wish.</p>

                    <h3>Final response letter</h3>

                    <ul class="list-disc list-outside ml-5 mb-5">
                        <li>The final response letter will explain our final position regarding the complaint. The
                            letter will</li>
                        <li>Adequately address the subject matter of the complaint</li>
                        <li>Summarise the outcome of our investigations</li>
                        <li>Say whether we acknowledge any fault on our part</li>
                        <li>Offer redress if the complaint is upheld</li>
                        <li>Where redress is financial, provide the complainant with fair compensation for any
                            acts or omissions for which we were responsible and comply with any offer of redress
                            which the complainant accepts</li>
                        <li>Inform the complainant that they may refer the complaint to the FOS if they are
                            dissatisfied with the final response and confirm that we will consider complaints
                            outside of the statutory timescales in place</li>
                        <li>Include a copy of the FOS’s explanatory leaflet </li>
                    </ul>

                    <p>There are three possible outcomes for a Final Response:</p>

                    <ul class="list-decimal list-outside ml-5 mb-5">
                        <li>we accept the complaint and offer redress</li>
                        <li>we offer redress without accepting the complaint</li>
                        <li>we reject the complaint and give our reasons</li>
                    </ul>

                    <p>Please note that redress will not always involve financial redress.</p>

                    <h3>The Financial Ombudsman Service (FOS)</h3>

                    <p>Our aim is to resolve all complaints internally, however if you are not satisfied with our
                    resolution, or if eight weeks have passed since the complaint was first brought to our
                        attention, you may have the right to refer the complaint to the FOS.</p>

                    <p>FOS contact details:</p>

                    <p><strong>Postal Address</strong><br/>
                    The Financial Ombudsman Service<br>
                    Exchange Tower<br>
                        London E14 9SR</p>

                    <h3>Telephone</h3>

                    <ul class="list-disc list-outside ml-5 mb-5">
                        <li>0800 023 4567 (calls should cost no more than 5p per minute for BT customers -
                        (other networks may vary)</li>
                        <li>0300 123 9123 (calls to this number will be cheaper if using a mobile phone)</li>
                        <li>020 7964 0500 (if caller is ringing from abroad)</li>
                        <li>complaint.info@financial-ombudsman.org.uk</li>
                    </ul>

                    <h3>www.financial-ombudsman.org.uk</h3>

                    <p>The FOS offers a free, independent service and they can help with most financial
                    complaints. However, there are some limitations on what the FOS can look into and further
                        information about this can be obtained from them directly.</p>
                </div>
            </div>

        </div>
        <CallBackForm />
    </main>

</template>

<script>
import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "Complaints",
    components: {
        AprRepresentative,
        CallBackForm,
        Button
    },
    metaInfo: {
        title: 'Complaints policy',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Complaints policy',
            subTitle: ''
        })
    }
}
</script>

<style scoped>
h2, h3, h4, h5, h6 {
    @apply font-bold mb-6 mt-4;
}
</style>
