<template>
    <section class="bg-gray-50 py-24 px-8 sm:px-6">
        <div class="flex-col justify-between max-w-screen-xl mx-auto text-center">
            <div class="grid md:grid-cols-2 lg:grid-cols-3 space-x-4 xl:space-x-8">
                <div v-for="(item, i) in items" :key="i" class="px-8 flex flex-wrap content-start space-y-8" data-aos="fade-up" data-aos-duration="500" :data-aos-offset="item.offset">
                    <img :src="require(`@/assets/images/${item.img}`)" :alt="item.title" class="shrink h-52 mx-auto" />
                    <div>
                        <h4 class="hdr-lead mb-3">{{ item.title }}</h4>
                        <p>{{ item.desc }}</p>
                    </div>
                </div>
            </div>
            <!--<div class="flex justify-center items-center py-8 mt-8">-->
            <!--    <Button @click.native="$emit('apply-url')" theme="primary" title="Get An Instant Secure Quote">-->
            <!--        Get an instant quote-->
            <!--    </Button>-->
            <!--</div>-->
        </div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
export default {
    name: "Process",
    components: {Button},
    data() {
        return {
            applyUrl: process.env.VUE_APP_APPLY,
            items: [
                {
                    title: 'Planet & People First',
                    desc: 'We are totally committed to reducing our carbon footprint and operating ethically & sustainably.',
                    img: 'planet-people-first.svg',
                    offset: '100'
                },
                {
                    title: 'change for good',
                    desc: 'Our vision is to constantly improve what we offer and what we do, so we can improve life for everybody.',
                    img: 'change-for-good.svg',
                    offset: '150'
                },
                {
                    title: 'Finvirotech',
                    desc: 'Our unique technology platform makes financing your car simple, convenient and sustainable.',
                    img: 'finvirotech.svg',
                    offset: '200'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
