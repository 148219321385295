<template>
    <section class="py-12 md:py-24 px-8 sm:px-6">
        <div class="flex-col justify-between max-w-screen-xl mx-auto">
            <div class="grid md:grid-cols-2 md:space-x-10">
                <div class="">
                    <h4 class="hdr-lead">About us</h4>
                    <h2 class="font-extrabold md:pr-16">We are a car finance lender, helping to make a difference.</h2>
                    <p class="text-xl font-light mt-8">We believe car finance should be straightforward to arrange, affordable to repay, easy to manage - and have a conscience.</p>

                    <Button @click.native="$router.push({name: 'about'})" theme="outlineRed" title="Find out more" class="mt-8" v-if="$route.name !== 'about'">Find out more</Button>
                </div>
                <div class="mt-16 md:mt-0 pt-16 md:pt-0 border-t border-gray-200 md:border-none">
                    <h4 class="hdr-lead">How can we help</h4>
                    <h2 class="font-extrabold">Frequently asked questions</h2>

                    <div class="mt-8">
                        <Accordion title="Can I end my finance contract early?">
                            <p>Yes, you can request a settlement figure from our Customer Care team at any time.</p>

                        </Accordion>
                        <Accordion title="Will I have to negotiate with your dealers?">
                            <p>No. There’s no need for you to haggle with the dealer as we operate a fixed price model. If you’re looking to buy a used car or a model that’s almost new from one of our dealers, we can handle the whole process for you.</p>
                        </Accordion>
                        <Accordion title="What documents do I need?">
                            <p>For car finance you will need a copy of your Full UK driving licence or EU driving licence or a proof of address, such as a recent utility bill or bank statement and a second proof of signature, such as a copy of your passport or debit card. Your driving licence should be in your current address. Our advisors will help you through this online or by phone.</p>
                        </Accordion>

                        <Accordion title="How long will the whole process take?">
                            <p>Used cars from our dealers are delivered as soon as possible once your finance agreement is complete and the funds have been transferred across to them. We’ve delivered cars in as little as 48 hours.</p>
                        </Accordion>
                        <Accordion title="What type of vehicles do you fund?">
                            <p>We will generally fund most cars but there can be some restrictions around how much we can lend against certain models, the age of the vehicle and other such things. If in doubt, you can see a list of cars which meet our lending criteria via your dashboard once you’ve applied. Alternatively you can contact the team if you require any assistance.</p>
                        </Accordion>

                        <Button @click.native="$router.push({name: 'faqs'})" theme="outlineRed" title="Find out more" class="mt-8">Find out more</Button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
import Accordion from "@/components/Unity/Accordion";

export default {
    name: "Information",
    components: {Button, Accordion}
}
</script>

<style scoped>

</style>
