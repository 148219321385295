<template>
    <main>
        <AprRepresentative />

        <div class="privacy max-w-screen-xl mx-auto py-20 px-8 sm:px-6 xxl:px-0">
            <div class="grid lg:grid-cols-2 lg:space-x-8">
                <div>
                    <p>Unity Auto Finance Ltd is the data controller for the purposes of the use of the personal information you provide to us.
	                    Further information about how we use your personal information can be sought from our Data Protection Officer.
                    </p>

                    <p>Unity Auto Finance Ltd, Unity House, Bird Hall Lane, Hercules Park, Stockport, Cheshire, SK3 0UX - Tel: 0330 9121002</p>

                    <p>Unity Auto Finance Ltd is authorised and regulated by the Financial Conduct Authority.</p>

                    <p>Our Financial Conduct Authority firm reference number is 630103.</p>

                    <p>We are a company registered in England and Wales and our company registration number is 04308759.</p>

                    <h3>How and why we use your personal information</h3>

                    <p>We will process the personal information you provide us when we have your consent to do so.
	                    In addition to this, we may also process your personal data based on our legitimate interests in doing so.
	                    This means that we will use your personal information in the manner you would expect us to with regard to
	                    arranging motor finance for you, as well as entering into a motor finance agreement with you.
                    </p>

                    <p>We will use the personal information you provide us with to progress your application for motor finance.
	                    This includes using your information such as your name, address and date of birth to check your credit profile,
	                    which allows us to assess whether we can enter into a credit agreement with you. We will carry out credit
	                    reference agency searches to find the most appropriate finance for your circumstances.
	                    We may also, where relevant information is available, consider any financial associates
	                    (this means people linked to you, typically following a joint finance application,
	                    for example a mortgage application) credit information in assessing your application.</p>

                    <p>We will share the personal information you provide to us with other organisations involved in your motor finance application.</p>

                    <p>Where we have permission to do so, we may provide you with marketing material in relation to products and services we may offer,
	                    where we do provide this information we will always give you an easy method to stop receiving it in the future.</p>

                    <p>In addition to the third parties listed below, we may ask for your permission to view your income and expenditure via your banking information.
	                    This will only ever take place where you give express consent to do so, and for the purpose of ensuring that you are only offered credit products
	                    that you can afford, or to allow us to help you manage your agreement with us, should you encounter financial difficulties.</p>

	                <p>We do require your personal data to be provided to us further to a direct contractual requirement with us, if you do not consent
	                    to us processing it this will mean that we will not be able to progress your finance application. We, at times, source personal data
	                    from third party lead generators. We will only ever use such third parties where they have been provided with consent to so provide us with your personal data.</p>

	                <p>We, along with the other organisations we may share your personal information with, will keep records of your finance application
	                and documents you provide to us, in addition to recordings of telephone conversations we and those other organisations may have with you.</p>

                    <h3>Categories of personal data</h3>

                    <p>We will not generally process any special categories of personal data, that is, information that is sensitive, such as:</p>

                    <ul class="list-disc list-inside mb-5">
                        <li>Race</li>
                        <li>Ethnic origin</li>
                        <li>Politics</li>
                        <li>Religion</li>
                        <li>Genetics</li>
                    </ul>

                    <p>In limited circumstances, and only with your consent, we may process information about your health. We will only ever do so where you disclose any health issues you may have to us
	                    and by using that information we can help with ensuring your finance application is progressed properly.
                    </p>

                    <h3>Who will we pass your personal data to</h3>

                    <p>We will only pass your personal data to third parties which need it in order to progress your application for finance.
	                    This means we will pass your personal data to credit reference agencies.
                    </p>

                    <p>The personal information we have collected from you will be shared with fraud prevention agencies who will use it to prevent
	                    fraud and money-laundering and to verify your identity. If fraud is detected, you could be refused certain services, finance or employment.
	                    For details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, please visit
	                    <a href="https://www.cifas.org.uk/fpn" target="_blank" title="https://www.cifas.org.uk/fpn" class="link text-unity-red">https://www.cifas.org.uk/fpn</a></p>

                    <p>Data shared with credit reference agencies and fraud prevention agencies is done so in accordance with the Credit Reference
	                    Agency Information Notice (“CRAIN”), which has been approved by the Information Commissioner's Office. This allows the UK’s three main credit
	                    reference agencies to share data in relation to credit activity. Full details about this can be found at the web addresses below:
                    </p>

                    <ul>
                        <li>Experian: <a href="https://www.experian.co.uk/crain" target="_blank" title="" class="link text-unity-red">www.experian.co.uk/crain</a></li>
                        <li>Equifax: <a href="https://www.equifax.co.uk/crain" target="_blank" title="" class="link text-unity-red">www.equifax.co.uk/crain</a></li>
                        <li>Transunion: <a href="https://www.transunion.co.uk/crain" target="_blank" title="" class="link text-unity-red">www.transunion.co.uk/crain</a></li>
                    </ul>

                    <h3>Who we may provide your personal data to</h3>

                    <p>We will only ever provide third parties with your personal information where we need to do so to progress your application,
	                    as such, we may provide it to any of the following third parties
	                    (further details of how your personal information is used by each of the third parties listed can be found by contacting us);</p>
                </div>
                <div>

                    <h4>Credit reference agencies</h4>
                    <ul>
                        <li> Experian Ltd - <a href="https://www.experian.co.uk/legal/privacy-statement.html" target="_blank" title="" class="link text-unity-red">experian.co.uk/legal/privacy-statement.html</a></li>
                        <li>Equifax Ltd - <a href="https://www.equifax.co.uk/About-us/Privacy_policy.html" target="_blank" title="" class="link text-unity-red">equifax.co.uk/About-us/Privacy_policy.html</a></li>
                    </ul>

	                <h4>Credit information services</h4>
	                <ul>
		                <li> Friendly Score UK Ltd - <a href="https://friendlyscore.com/page/privacy-policy" target="_blank" title="" class="link text-unity-red">friendlyscore.com/page/privacy-policy</a></li>
	                </ul>

                    <h4>Fraud prevention </h4>
                    <ul>
                        <li>SIRA - <a href="https://www.synectics-solutions.com/privacy" target="_blank" title="" class="link text-unity-red">https://www.synectics-solutions.com/privacy</a></li>
                        <li>CIFAS - <a href="https://www.cifas.org.uk/fpn" target="_blank" title="" class="link text-unity-red">https://www.cifas.org.uk/fpn</a></li>
                    </ul>

                    <h4>Credit brokers</h4>
                    <p>DSG Financial Services Ltd - <a href="https://www.dsgfs.com/privacy" target="_blank" title="" class="link text-unity-red">dsgfs.com/privacy</a></p>

                    <h4>Third party service providers</h4>

                    <div class="bg-gray-100 p-8">
                        <h5>Data storage</h5>
                        <ul>
                            <li>Amazon Web Service - <a href="https://www.aws.amazon.com/privacy/" target="_blank" title="" class="link text-unity-red">aws.amazon.com/privacy/</a></li>
                            <li>Google Cloud Platform - <a href="https://www.cloud.google.com/security/privacy/" target="_blank" title="" class="link text-unity-red">cloud.google.com/security/privacy/</a></li>
                            <li>Selestial HQ - <a href="https://selestial.com/privacy/" target="_blank" title="" class="link text-unity-red">https://selestial.com/privacy/</a></li>
                            <li>Microsoft Azure - <a href="https://azure.microsoft.com/en-gb/support/legal/" target="_blank" title="n" class="link text-unity-red">https://azure.microsoft.com/en-gb/support/legal/</a></li>
                        </ul>

                        <h5>Electronic signature platform providers</h5>
                        <p>Bonafidee Ltd - <a href="https://www.bonafidee.com/privacy" target="_blank" title="" class="link text-unity-red">bonafidee.com/privacy</a></p>

                        <h5>Communications providers</h5>
                        <ul>
                            <li>Essendex Ltd - <a href="https://esendex.co.uk/privacy-policy" target="_blank" title="" class="link text-unity-red">esendex.co.uk/privacy-policy</a></li>
                            <li>Xima Software Ltd - <a href="http://www.ximasoftware.com/general-privacy-policy.html " target="_blank" title="">http://www.ximasoftware.com/general-privacy-policy.html</a></li>
                            <li>Credit Kudos Ltd - <a href="https://www.creditkudos.com/legal/privacy" target="_blank" title="" class="link text-unity-red">https://www.creditkudos.com/legal/privacy</a></li>
                        </ul>

                        <h5>Software</h5>
                        <p>Anchor Computer Systems (Sentinel) - <a href="https://www.anchor.co.uk/privacy-cookies/" target="_blank" title="" class="link text-unity-red">https://www.anchor.co.uk/privacy-cookies/</a></p>

                        <h5>Collections</h5>
                        <ul>
                            <li>Peak Collections Ltd</li>
                            <li>Credit Solutions Ireland Ltd</li>
                            <li>Towerhall Solutions Ltd - <a href="https://www.towerhallsolutions.com/privacy-policy/" target="_blank" title="" class="link text-unity-red">https://www.towerhallsolutions.com/privacy-policy/</a></li>
                            <li>Hillside Services Ltd</li>
                            <li>Manheim Cox Automotive - <a href="https://www.manheim.co.uk/legal/privacy-policy" target="_blank" title="" class="link text-unity-red">https://www.manheim.co.uk/legal/privacy-policy</a></li>
                            <li>Aston Barclay - <a href="https://www.astonbarclay.net/privacy/" target="_blank" title="" class="link text-unity-red">https://www.astonbarclay.net/privacy/</a></li>
                            <li>City Auction group - <a href="https://www.cityauctiongroup.com/privacy-notice" target="_blank" title="" class="link text-unity-red">https://www.cityauctiongroup.com/privacy-notice</a></li>
	                        <li>Motor Auction Group (MAG) - <a href="https://www.mag.co.uk/PrivacyPolicyAndDisclaimer" target="_blank" title="" class="link text-unity-red">https://www.mag.co.uk/PrivacyPolicyAndDisclaimer</a></li>
	                        <li>Legate Group - <a href="https://www.legategroup.co.uk/privacy-policy/" target="_blank" title="" class="link text-unity-red">Privacy Policy – Legate Group</a></li>
                        </ul>

                        <h5>Legal</h5>
                        <ul>
                            <li>Auxillias -<a href="https://auxillias.com/privacy-policy" target="_blank" title="" class="link text-unity-red"> https://auxillias.com/privacy-policy</a></li>
                            <li>TLT Solicitors - <a href="https://www.tltsolicitors.com/privacy/" target="_blank" title="" class="link text-unity-red">https://www.tltsolicitors.com/privacy/</a></li>
                        </ul>
                    </div>

                    <h4>Details of transfers to third country and safeguards</h4>

                    <p>Whilst our own data storage facilities are based in the UK, we may also use “Cloud” based data storage solutions to process your data.
	                    Where we make use of such storage, we will ensure that your personal data is kept safe. Where the Cloud provider transfers data outside of the European Economic Area,
	                    we will ensure that that Cloud provider only transfers personal data to countries with adequate data protection measures in place.<p>

                    <h4>Retention of your personal data</h4>

                    <p>We will ensure that we only retain your personal data for as long as we need to, specifically with
	                    regard to the reason you provided it to us in the first place. We will retain your personal data
	                    for a longer period than the length of any finance agreement you enter into as a result of our
	                    processing of your personal data. We will retain your personal data in this manner to be able
	                    to provide you or any third party involved in your finance application with information in
	                    relation to that application. We will also retain your personal data to allow us to resolve any complaints.
                    </p>

                    <h4>Your rights with regard to your personal data</h4>

                    <p>You have the right to the following with regard to your personal data:</p>

                    <ul class="list-disc list-inside mb-5">
                        <li>The right to object to processing, or to request we process your personal data for limited purposes</li>
                        <li>The right to have your personal data removed from our records</li>
                        <li>The right to have the details of your personal data amended if what we hold is wrong</li>
                        <li>The right to have your personal data transported to another organisation</li>
                        <li>The right to request a review of any automated decision making we use with regard to your finance application</li>
                        <li>The right to be provided with copies of the personal data we hold about you</li>

                    </ul>

                    <p>You also have the right to withdraw your consent for us to process your personal data at any time.</p>

                    <p>Should you wish to exercise any of these rights, or need any further information, please contact the Data Protection Officer using the contact details listed above
                    </p>

                    <h4>Complaints</h4>

                    <p>If you have any complaints with regard to our data processing practices, we will be happy to assist. In these circumstances, please contact the Data Protection Officer. If you are not satisfied with this, you may contact the Information Commissioner’s Office (the UK regulator with regard to data protection). You may do so by calling 0303 123 1113 or visiting  <a href="https://www.ico.org.uk" target="_blank" title="www.ico.org.uk" class="link text-unity-red">www.ico.org.uk</a></p>

                    <p>We make use of automated credit decision making tools to allow us to process your finance application in the most efficient and accurate manner possible. We, and the third parties we may pass your personal data to make decisions on whether to grant credit to individuals based on an algorithmic process.
                    </p>

                    <p>Should you object to any decision made in relation to your finance application, please contact the Data Protection Officer using the contact details above.
                    </p>
                    <small>Privacy Policy v.1.8</small>
                </div>
            </div>

        </div>

        <CallBackForm />
    </main>
</template>

<script>
import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import AprRepresentative from "@/components/Unity/AprRepresentative";
export default {
    name: "Privacy",
    components: {
        AprRepresentative,
        CallBackForm,
        Button
    },
    metaInfo: {
        title: 'Privacy policy',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Privacy policy',
            subTitle: ''
        })
    }
}
</script>

<style scoped lang="scss">
h2, h3, h4, h5, h6 {
    @apply font-bold mb-6 mt-4;
}

</style>
