import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import About from '@/views/About'
import Faqs from "@/views/Faqs";
import OurWayOfLife from "@/views/OurWayOfLife";
import Products from "@/views/Products";
import PcpExplained from "@/views/PcpExplained";
import HpExplained from "@/views/HpExplained";
import EligibilityChecker from "@/views/EligibilityChecker";
import CarFinance from "@/views/CarFinance";
import Complaints from "@/views/Complaints";
import ComplaintsHandling from "@/views/ComplaintsHandling";
import Privacy from "@/views/Privacy";
import Terms from "@/views/Terms";
import News from "@/views/News";
import NewsPost from "@/views/NewsPost";
import NotFound from '@/views/NotFound'
import Maintenance from '@/views/Maintenance'
import Meta from 'vue-meta'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/about-us',
            name: 'about',
            component: About
        },
        {
            path: '/faqs',
            name: 'faqs',
            component: Faqs
        },
        {
            path: '/our-way-of-life',
            name: 'OurWayOfLife',
            component: OurWayOfLife
        },
        {
            path: '/products',
            name: 'products',
            redirect: '/',
            component: Products
        },
        {
            path: '/hp-explained',
            name: 'hp-explained',
            component: HpExplained
        },
        {
            path: '/eligibility-checker',
            name: 'eligibility-checker',
            component: EligibilityChecker
        },
        {
            path: '/car-finance',
            name: 'car-finance',
            component: CarFinance
        },
        {
            path: '/complaints',
            name: 'complaints',
            component: Complaints
        },
        {
            path: '/complaints-handling-procedure',
            name: 'complaints-handling-procedure',
            component: ComplaintsHandling
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms
        },
        {
            path: '/blog',
            name: 'news',
            component: News
        },
		{
			path: '/blog/:slug',
			name: 'news-post',
			component: NewsPost
		},
		{
			path: '/maintenance',
			name: 'maintenance',
			component: Maintenance,
			meta: {
				banner: false
			}
		},
        {
            path: '*',
            name: 'not-found',
            component: NotFound,
        },
    ]
})

router.beforeEach(async (to, from, next) => {
    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    };
    scrollToTop();
    next()
})

router.afterEach(async () => {

    //Trigger Hubspot's tracking
    let _hsq = window._hsq = window._hsq || [];

    _hsq.push(['trackPageView']);
})

export default router
