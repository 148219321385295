<template>
    <section id="callback" class="unity-registration-form bg-unity-dark-grey py-12 md:py-20 px-8 sm:px-6 relative text-center border-b border-gray-600">
        <template>
             <h4 class="hdr-lead mb-3 text-white">Here to help</h4>
             <h2 class="text-white font-extrabold">Request a call back</h2>
        </template>
        <div class="max-w-screen-xl mx-auto px-0 lg:px-16">
            <div id="hubspotForm" v-once class="mt-12 xl:flex justify-center"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: "CallBackForm",
    mounted() {
        const script = document.createElement("script");
        script.src="https://js-eu1.hsforms.net/forms/embed/v2.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "25314347",
                    formId: "01f1c871-870e-4b06-be94-c6134324418a",
                    target: "#hubspotForm"
                })
            }
        })
    }
}
</script>
