<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click="close">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <div class="modal-backdrop">
                    <div class="modal w-11/12 md:w-1/2 lg:w-2/4 xl:w-2/5 " :class="{'modal px-8 py-8 lg:py-12 rounded-md bg-white': isRounded, '': isSquare, 'bg-unity-red text-white hdr-white': isRed, 'bg-unity-yellow text-white': isYellow, 'bg-unity-bluegreen text-white': isBlueGreen, 'bg-unity-dark-grey text-white': isGrey}">

                        <div @click="close" aria-label="Close modal" class="btn-close p-2 rounded-full bg-black bg-opacity-20 absolute cursor-pointer text-white hover:bg-opacity-40">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </div>

                        <section class="modal-body relative" id="modalDescription">
                            <slot name="body">
                                This is the default body!
                            </slot>
                        </section>

                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        isRounded: {
            type: Boolean,
            default: false,
        },
        isSquare: {
            type: Boolean,
            default: false,
        },
        isRed: {
            type: Boolean,
            default: false,
        },
        isBlueGreen: {
            type: Boolean,
            default: false,
        },
        isYellow: {
            type: Boolean,
            default: false,
        },
        isGrey: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped lang="scss">
    .modal-backdrop {
        @apply fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-50;
        background-color: rgba(0, 0, 0, 0.3);

        .modal {
            @apply flex flex-col shadow-md relative;
        }

        .modal-header,
        .modal-footer {
            @apply flex p-8 py-6;
        }

        .btn-close {
            top: 5px;
            right: 5px;
        }

        .modal-header {
            @apply text-unity-coffee relative border-b border-gray-200 justify-between;

            .btn-close {
                @apply absolute top-0 right-0 border-none p-4 cursor-pointer font-bold bg-transparent;

                font-size: 20px;
                color: #4AAE9B;
            }
        }

        .modal-footer {
            @apply border-t border-gray-200 justify-end flex-col;

            .btn-green {
                color: white;
                background: #4AAE9B;
                border: 1px solid #4AAE9B;
                border-radius: 2px;
            }
        }

        .modal-body {
            @apply relative;
        }
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }
</style>
