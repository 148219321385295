import { extend } from 'vee-validate'
import { required, numeric, min, min_value } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: (field) => {return 'This field is required'}
})

extend("numeric", numeric)

extend('min_value', {
  ...min_value,
  message: (field, rules) => 'The minimum value is ' + rules.min
})

extend("min", {
  ...min,
  validate: (value, rules) => {
    if(!value){
      return false
    }
    if(!value.length){
      return false
    }
    return value.trim().length >= rules.length
  },
  message: (field, rules) => 'This fields needs at least ' + rules.length + ' characters'
})

extend("smaller_than", {
  validate: (value, {compareTo}) => {
    return parseInt(value) <= parseInt(compareTo)
  },
  message: "Invalid amount",
  params: ['compareTo'],
})

const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

extend('url', {
  validate: (value) => {
    if(value.indexOf('.') < 0){
      return false
    }
    const url = withHttp(value)
    try{
      new URL(url)
      return true
    }
    catch(e){
      return false
    }
  },
  message: "Invalid URL",
})