<template>
    <a :href="href" :class="themes.base.concat(themes[theme])">
        <slot v-if="theme !== 'loading'" />
        <template v-else>Loading</template>
    </a>
</template>

<script>
export default {
    name: "Button",

    props: {
        href: {
            type: String,
            required: false,
        },
        route: {
            type: String,
            required: false,
        },
        theme: {
            type: String,
            default: "primary"
        }
    },

    data() {
        return {
            themes: {
                base: [
                    "inline-block",
                    "px-8",
                    "py-3",
                    "rounded-full",
                    "uppercase",
                    "font-bold",
                    "hover:transition",
                    "cursor-pointer",
                    "text-center"
                ],
                primary: [
                    "bg-unity-red",
                    "text-white",
                    "border-2",
                    "border-transparent",
                    "hover:border-unity-red",
                    "hover:bg-transparent",
                    "hover:text-white"
                ],
                primaryLight: [
                    "bg-unity-red",
                    "text-white",
                    "border-2",
                    "border-transparent",
                    "hover:border-unity-red",
                    "hover:bg-transparent",
                    "hover:text-unity-red"
                ],
                primaryGreen: [
                    "bg-unity-bluegreen",
                    "text-white",
                    "border-2",
                    "border-transparent",
                    "hover:border-unity-bluegreen",
                    "hover:bg-transparent",
                    "hover:text-unity-bluegreen"
                ],
                secondary: [
                    "bg-unity-dark-grey",
                    "text-white",
                    "border-2",
                    "border-transparent",
                    "hover:border-unity-dark-grey",
                    "hover:bg-transparent",
                    "hover:text-white"
                ],
                ternary: [
                    "bg-unity-yellow",
                    "text-white",
                ],
                outlineRed: [
                    "bg-none",
                    "text-unity-red",
                    "border-2",
                    "border-unity-red",
                    "hover:bg-unity-red",
                    "hover:text-white"
                ],
                outlineGrey: [
                    "bg-none",
                    "text-unity-dark-grey",
                    "border-2",
                    "border-unity-dark-grey",
                    "hover:bg-unity-dark-grey",
                    "hover:text-white"
                ],
                outlineYellow: [
                    "bg-none",
                    "text-unity-yellow",
                    "border-2",
                    "border-unity-yellow",
                    "hover:bg-unity-yellow",
                    "hover:text-unity-dark-grey"
                ],
                outlineWhite: [
                    "bg-none",
                    "text-white",
                    "border-2",
                    "border-white",
                    "hover:bg-white",
                    "hover:text-unity-red"
                ],
                loading: [
                    "bg-gray-300",
                    "text-gray-500"
                ]
            }
        }
    }
}
</script>
