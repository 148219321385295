<template>
    <div id="app" class="relative overflow-hidden" :class="$route.name === 'home' || $route.name === 'about' ? 'home-page' : 'inner-page'">
        <Header @dashboard-login="showLoginModal()" @apply-url="apply()" />

        <Modal v-show="isModalLoginVisible" @close="closeLoginModal" isRounded>
            <template v-slot:body>
                <h3 class="font-bold mb-8">Let’s get you to the right place</h3>
                <div class="grid md:grid-cols-2 md:space-x-4 mt-4">
                    <div class="md:border-r md:border-gray-200 md:pr-4 mb-8 md:mb-0 flex flex-wrap content-between">
                        <p class="mb-auto">I’ve got an existing car on finance with Unity and I want to manage my account.</p>
                        <Button class="mt-4 md:mt-8 w-full" theme="outlineGrey" href="https://unity.anchor.co.uk/Login" target="_blank">Login</Button>
                    </div>
                    <div class="flex flex-wrap content-between">
                        <p class="mb-auto">I’ve applied for finance but I haven’t taken delivery of my car.</p>
                        <Button class="mt-4 md:mt-8 w-full" theme="primaryLight" :href="getLoginUrl()">Login</Button>
                    </div>
                </div>
            </template>
        </Modal>

        <Banner v-if="$route.meta.banner ?? true" :title="titles.title" :sub-title="titles.subTitle" />
	      <div v-else class="nav-spacer"></div>
        <router-view @update-banner-title="updateBannerTitle" @apply-url="apply()"></router-view>
        <Footer />
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Banner from '@/components/Banner';
    import Footer from '@/components/Footer';
    import Modal from "@/components/Unity/Modal";
    import Button from "@/components/Unity/Button";

    export default {
        name: 'App',

        components: {
            Button,
            Header,
            Banner,
            Footer,
            Modal
        },

        metaInfo: {
            titleTemplate: 'Unity - %s'
        },

        data() {
            return {
		            applyUrl: process.env.VUE_APP_APPLY,
		            applyUrlDev: process.env.VUE_APP_APPLY_DEV,
		            loginUrl: process.env.VUE_APP_LOGIN,
		            loginUrlDev: process.env.VUE_APP_LOGIN_DEV,
                titles: {
                    title: null,
                    subTitle: null
                },
                isModalLoginVisible: false,
            }
        },

        methods: {
            updateBannerTitle(data) {
                if(typeof data.title !== "undefined" && data.title !== null) {
                    this.titles.title = data.title
                }
                if(typeof data.subTitle !== "undefined" && data.subTitle !== null) {
                    this.titles.subTitle = data.subTitle
                }
            },

            showLoginModal() {
                this.isModalLoginVisible = true;
            },

            closeLoginModal() {
                this.isModalLoginVisible = false;
            },

            apply() {
								window.location.href = process.env.NODE_ENV === 'production' ? this.applyUrl : this.applyUrlDev
            },

		        getLoginUrl() {
								return process.env.NODE_ENV === 'production' ? this.loginUrl : this.loginUrlDev
		        }
        },
        mounted() {
            // Set GTM enable to true after vue pages are loaded
            this.$gtm.enable(true)
        }
    };
</script>

<style lang="scss">
    @import "./scss/app";
</style>
