<template>
	<main>
		<AprRepresentative/>

		<div class="complaints max-w-screen-xl mx-auto py-20 px-8 sm:px-6 xxl:px-0">
			<div class="grid lg:grid-cols-2 lg:space-x-8">
				<div>
					<p>It is the aim of Unity Auto Finance Ltd to provide a very high standard of service to every client. It is
						important to us that all complaints are resolved as quickly as possible and to the complete satisfaction of
						our clients.</p>

					<p>This procedure explains how we will deal with any complaints. It also tells you what you can do if you
						think your complaint has not been resolved to your satisfaction.</p>

					<p>If you have a complaint about any aspect of our service then we would like to hear from you. You can
						contact us by telephone or in writing and your complaint will be resolved by the appropriate person in the
						shortest possible time.</p>

					<p>To help us to investigate and resolve your concerns as quickly as possible, you should in the first
						instance contact the department with which you have been dealing.</p>

					<p>To help us resolve your problem you should provide the following information:</p>

					<ul class="list-inside list-disc mb-6">
						<li>Your full name and contact information</li>
						<li>Full details of your complaint</li>
						<li>Your agreement details</li>
						<li>Details of what you would like us to do to put things right</li>
						<li>Photocopies of any relevant paperwork</li>
					</ul>

					<p>We will try to resolve your complaint immediately; however, sometimes this may not be possible. In the
						unlikely event that we are not able to resolve your complaint by the end of the third business day after it
						is received, we will keep you informed of the progress of our investigations and provide our final response
						in writing providing our findings and the action to then take.</p>

					<p>If you have arranged a regulated finance agreement with us and are not satisfied with our final response,
						you may be eligible to refer the matter to the Financial Ombudsman Service. You must do this within six
						months of our final response. When we send you our final response, we will also provide you with a copy of
						the Financial Ombudsman Service's explanatory leaflet.</p>
				</div>

				<div>
					<p>The Financial Conduct Authority has issued a 37 week pause to the complaints process that we must follow,
						where the complaint relates to motor finance agreements that included discretionary commission arrangements.
						This has been introduced to allow the Financial Conduct Authority to conduct a review and provide further
						guidance on how this type of complaint should be handled.</p>

					<p>If you complained to us about a discretionary commission arrangement on or after 17th November 2023, we may
						not issue you with a final response to your complaint during the pause period. Final responses to complaints
						are usually issued within 8 weeks from the date you made a complaint to us. We will however continue to
						investigate such complaints and we will issue final responses after the Financial Conduct Authority
						concludes its investigation, which is expected by the end of September 2024.</p>

					<p>If you have already made a complaint of this nature to us, and we provide you with a final response to that
						complaint between the 12th July 2023 and 20th November 2024, you will have longer to refer it to the
						Financial Ombudsman Service. Instead of 6 months from the date of receiving our final response, you will
						have 15 months to make such a referral if you wish to do so.</p>

					<p>For more information in relation to this please visit this link - <a
						href="https://www.fca.org.uk/consumers/car-finance-complaints" target="_blank">FCA consumer webpage</a></p>

					<p>If you have any questions in relation to our Complaints Handling Procedure, please contact us and we will
						be happy to assist you.</p>

					<div class="p-8 bg-unity-dark-grey mt-5 text-lg text-unity-light-grey">
						Unity Auto Finance Ltd, Unity House, Bird Hall Lane, Cheadle, Cheshire, SK3 0UX, t. 0330 912 1002, e.
						<a href="mailto:hello@unityauto.finance">hello@unityauto.finance</a>
					</div>
				</div>
			</div>
		</div>
		<CallBackForm/>
	</main>
</template>

<script>
import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
	name: "ComplaintsHandling",
	components: {
		AprRepresentative,
		CallBackForm,
		Button
	},
	metaInfo: {
		title: 'Complaints handling procedure',
	},
	mounted() {
		this.$emit('update-banner-title', {
			title: 'Complaints handling procedure',
			subTitle: ''
		})
	}
}
</script>

<style scoped>

</style>
