export function mapFields(options) {
    const object = {};
    for (let x = 0; x < options.fields.length; x++) {
      const field = [options.fields[x]];
      object[field] = {
        get() {
          return this.$store.state[options.base][field];
        },
        set(value) {
          this.$store.commit(options.mutation, { [field]: value });
        }
      };
    }
    return object;
  }
  
  export function stripMobilePrefix(number)
  {
    number = number.replace(/^(\+)/,"")
    number = number.replace(/^(44)/,"")
    const zeroRegex = /^0/i;
    if(zeroRegex.exec(number) === null){
      number = "0" + number
    }
    return number
  }
  
  export function getGoogleCid()
  {
    return new Promise((resolve,reject) => {
      if(typeof window.ga === 'undefined'){
        reject(new Error('ga is undefined'))
      }
      window.ga(function(tracker) {
        const ga_cid = tracker.get('clientId');
        resolve(ga_cid)
      });
    })
  }
  
  export function addNumberSeparators(number)
  {
      let nStr = number
        nStr+= ''
      const comma = /,/g
      nStr = nStr.replace(comma,'')
      x = nStr.split('.')
      x1 = x[0]
      x2 = x.length > 1 ? '.' + x[1] : ''
      const rgx = /(\d+)(\d{3})/
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2')
      }
      return x1 + x2
  }
  
  
  export function getRouteByComponentName(routes, name)
  {
    for(let i in routes){
      let currentRoute = routes[i]
  
      if(typeof currentRoute.component !== 'undefined' && currentRoute.component.name === name){
        return currentRoute
      }
  
      if(typeof currentRoute.children !== 'undefined' && currentRoute.children.length){
        let subResult = getRouteByComponentName(currentRoute.children, name)
        if(subResult !== null){
          return subResult
        }
      }
    }
    return null
  }
  
  export function getRouteFullPathByComponentName(routes, name)
  {
    let path = ''
    for(let i in routes){
      let currentRoute = routes[i]
  
      if(typeof currentRoute.component !== 'undefined' && currentRoute.component.name === name){
        path = currentRoute.path
      }
  
      if(typeof currentRoute.children !== 'undefined' && currentRoute.children.length){
        let subResult = getRouteByComponentName(currentRoute.children, name)
        if(subResult !== null){
          path = currentRoute.path + "/" + subResult.path
        }
      }
    }
    return path
  }
  