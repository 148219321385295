<template>
    <main>
        <AprRepresentative />

        <div class="privacy max-w-screen-xl mx-auto py-20 px-8 sm:px-6 xxl:px-0">
            <div class="grid lg:grid-cols-2 lg:space-x-8">
                <div>
                    <h2>General Use of Site</h2>

                    <p>1)  The website located at www.unityauto.finance (the "Site") is operated by Unity Auto Finance Limited ("we", "our", "us", "Unity Auto Finance"). We are a private limited company registered in England and Wales under company number 04308759, at our registered office at ​​Unity House, Bird Hall Lane, Stockport, Cheshire, SK3 0UX</p>

                    <p>2) The site provides products and services related to car finance and vehicle sales. The Site also advertises vehicles for sale by third-party sellers on our web portal referred to in these Terms as "My Dashboard".</p>

                    <p>3) By accessing and using the Site you agree to be bound by and to act in accordance with these terms and conditions.</p>

                    <p>4) These terms operate in conjunction with our Privacy Policy and Cookie Policy (together the "Terms") and apply to your usage of this Site.</p>

                    <p>5)​ We are authorised and regulated by the Financial Conduct Authority (Firm reference number 630103). As such, we have certain duties and responsibilities towards you when we conduct business with you. Nothing in these Terms will affect the duties and responsibilities that we have to you in respect of our Regulated Business. In particular, we will use our best endeavours to ensure that information provided to you on parts of the Site which concern your application for finance will be accurate at all times and this includes our being responsible for checking any content provided to us by third parties.</p>

                    <p>6)​ We take your privacy seriously and process information about you in accordance with our Privacy Policy.</p>

                    <p>7)​ By agreeing to these Terms, you are providing your consent for us to use cookies in the ways described in our Cookie Policy, please see our Cookie Policy for full details.</p>

                    <h2>Links & Third Party Content</h2>

                    <p>1)​ Parts of the Site may contain material provided by third-party product or service providers. Each 3rd party is responsible for ensuring that all material submitted for inclusion on the Site complies with all applicable laws. We will not be responsible if any such material is untrue, inaccurate, incomplete and/or contains errors and we disclaim all liability and responsibility arising from any reliance you place on such material.</p>

                    <p>2)​ We may update our site from time to time and may change the content at any time. However, please note that any of the content on our site may be out of date at any given time, and we are under no obligation to update it.</p>

                    <p>3)​ We do not guarantee that our site, or any content on it, will be free from errors or omissions.</p>

                    <p>4)​ You acknowledge that the Site contains links to other websites. We do not review these third party websites nor have any control over them and we are not responsible for the websites or their content or availability.</p>

                    <h2>Intellectual Property Rights</h2>

                    <p>1)​ We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>

                    <p>2)​ You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>

                    <p>3)​ You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>

                    <p>4) ​ Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>

                    <p>5)​ You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>

                    <p>6) ​ If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No reliance on information.</p>

                    <p>7)​ The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>

                    <p>8)​ Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.</p>

                    <p>9)​ Our content includes any information or other material found on or via the Site, including text, databases, graphics, videos, software and all other features found on or via the Site.</p>

                    <p>10)​ No licence is granted to you in these Terms to use any trademark of Unity Auto Finance.</p>

                    <h2>Accessing Our Site</h2>

                    <p>1)​ You must be aged 18 or over to access our site.</p>

                    <p>2)​ Our site is made available free of charge.</p>

                    <p>3)​ We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. Access to our site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our site without notice. We will not be liable to you if for any reason our site is unavailable at any time or for any period.</p>

                    <p>4) ​ You are responsible for making all arrangements necessary for you to have access to our site.</p>

                    <p>5)​ You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions and that they comply with them.</p>

                    <p>6) ​ Unity Auto Finance ("we" or "us") and potential lenders, service providers, vehicle suppliers and agents will use your personal details (which may include sensitive personal data) and information we obtain from other sources to assess your application, process credit searches, for marketing of our vehicle finance and associated products and to ensure that the services and advertising we offer are tailored to your needs and interests. We, potential lenders, service providers and agents may contact you by mail, telephone, email, SMS or other electronic messaging services with offers of related products and services that may be of interest to you where there is a contractual requirement, a legitimate interest or you have consented to do so. By providing us with your contact details, you agree to being contacted by the methods and for the purposes set out in, and in accordance with, our Privacy Policy. In assessing your car loan application, we will make enquiries about you including searching your record with selected credit reference agencies. You consent to us disclosing details of your finance application and we may use a 'credit scoring' or other automated process in deciding whether to accept your application. If false or inaccurate information is provided and fraud is identified, we are obliged to pass your details on to fraud prevention agencies to prevent fraud and money laundering.</p>

                </div>

                <div>

                    <h2>Limitation of Our Liability</h2>

                    <p>1) ​ Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.</p>

                    <p>2)​ To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied.</p>

                    <p>3)​ We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: - use of, or inability to use, our site; or - use of or reliance on any content displayed on our site.</p>

                    <p>4)​ If you are a business user, please note that in particular, we will not be liable for:</p>

                    <ul class="list-outside list-disc mb-6 ml-5">
                        <li>loss of profits, sales, business, or revenue;</li>
                        <li>business interruption;</li>
                        <li>loss of anticipated savings;</li>
                        <li>loss of business opportunity, goodwill or reputation; or any indirect or consequential loss or damage.</li>
                    </ul>

                    <p>5) ​ If you are a consumer user, please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>

                    <p>6)​ We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.</p>

                    <p>7)​ We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as endorsements by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</p>

                    <h2>Uploading Content to Our Site</h2>

                    <p>1)​ Whenever you make use of a feature that allows you to upload content to our site, you must comply with the content standards set out in our Acceptable Use Policy.</p>

                    <p>2) ​ You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. [If you are a consumer user, this means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.]</p>

                    <p>3)​ Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us a limited licence to use, store and copy that content and to distribute and make it available to third parties.</p>

                    <p>4)​ We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user of our site.</p>

                    <h2>Communications</h2>

                    <p>1)​ Unity Auto Finance offers various methods of communication including:</p>

                    <ul class="list-outside list-disc mb-6 ml-5">
                        <li>Web chat service ("​ Chat​ ")</li>
                        <li>SMS chat service ("​ Chat​ ")</li>
                        <li>Whatsapp (“​ Chat​ ”)</li>
                        <li>Facebook Messenger (“​ Chat​ ”)</li>
                        <li>Phone Calls</li>
                        <li>E-mails</li>
                    </ul>

                    <p>2)​ If you choose to start a communication session via ​ Chat​ , you must ensure that you use the mobile telephone number used to register for your Unity Auto Finance account. We will have verified this phone by sending a 6-digit code and may ask you to re-verify at regular intervals to protect your sensitive information.</p>

                    <p>3)​ A recording of each communication will be held indefinitely and may be used by us to assess your application, for quality and training purposes and for where legally we are required. For further details of the personal information we collect from you and how we use it, please review our Privacy Policy.</p>

                    <p>4)​ Site or Service Feedback, if you have any general complaints or wish to request further information about the Site, please contact us via e-mail at assistance@flowcarfinance.co.uk​ or by post at ​ Unity House, Bird Hall Lane, Stockport, Cheshire, SK3 0UX.</p>

                    <p>5) ​ Changes: We may make changes to the Terms at any time by sending you an email with the modified terms or by posting a copy of them on the Site. Any changes will take effect 7 days after the date of our email or the date on which we post the modified terms on the Site, whichever is the earlier. If you continue to use the Site after that period has expired, it means that you accept any such changes.</p>

                    <p>6)​ Interpretation: Headings in these Terms are for convenience only and will not affect the interpretation of these Terms.</p>

                    <p>7)​ In these Terms, words that appear after the expression "include", "including", "other", "for example", "such as" or "in particular" (or similar expression) will not limit the meaning of the words appearing after such expression.</p>

                    <p>8)​ You may not assign, sub-license or otherwise transfer any of your rights under these Terms.</p>

                    <p>9)​ If any provision in these Terms is found by any court or other body of competent jurisdiction to be wholly or partly invalid or unenforceable then that provision or part will be severed from these Terms and the remaining provisions will continue in full force and effect.</p>

                    <p>10) ​ If you breach these Terms, a waiver of any right under these Terms is only effective if it is in writing and shall not be deemed to be a waiver of our right to act in respect of any subsequent or similar breach.</p>

                    <p>11)​ We will not be responsible for any breach of these Terms or otherwise for any delay or failure in processing your application for any loan or other product or service where such breach, delay or failure is attributable to you and/or any third party or is otherwise caused by circumstances beyond our control.</p>

                    <p>12) ​ These Terms shall be governed by and construed in accordance with the laws of England and each of you and Unity Auto Finance irrevocably submits to the exclusive jurisdiction of the English courts in relation to all disputes arising out of or in connection with these Terms.</p>

                    <h2>Contact Us</h2>

                    <p>This Site is owned by Unity Auto Finance Limited, a company incorporated in England. The registered office address of Unity Auto Finance Limited is: ​Unity House, Bird Hall Lane, Stockport, Cheshire, SK3 0UX</p>

                </div>
            </div>

        </div>

        <CallBackForm />
    </main>
</template>

<script>
import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import AprRepresentative from "@/components/Unity/AprRepresentative";
export default {
    name: "Privacy",
    components: {
        AprRepresentative,
        CallBackForm,
        Button
    },
    metaInfo: {
        title: 'Terms & Conditions',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Terms & Conditions',
            subTitle: ''
        })
    }
}
</script>

<style scoped lang="scss">
h2, h3, h4, h5, h6 {
    @apply font-bold mb-6 mt-4;
}

</style>
