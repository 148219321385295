<template>
    <main class="mossy-earth">

        <AprRepresentative />

        <section class="grid lg:grid-cols-2 xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">Our commitment to the planet</h4>
                    <h2 class="font-extrabold mb-8">Remember, it's bigger than this</h2>

                    <p class="text-xl font-light">We’re conscious of the impact our industry has on the environment. As part of the DSG Finance Group, our growing concerns about climate change started off Unity’s journey to see how we could become more sustainable as a company and reduce the carbon footprint created by the day-to-day operations of our business and the cars we finance.</p>
                    <p>We are a <a href="https://plannetzero.org/" title="Planet Zero" target="_blank" class="font-bold underline hover:no-underline text-unity-red">Plannet Zero</a> certified carbon neutral business, and we work with <a href="https://www.mossy.earth/" title="Mossy Earth" target="_blank" class="font-bold underline hover:no-underline text-unity-red">Mossy Earth</a> on reforestation and rewilding projects. We also work extremely hard to ensure </p>
                    
                    <!--<Button @click.native="$emit('apply-url')" theme="primaryGreen" title="Get An Instant Secure Quote">-->
                    <!--    Get an instant quote-->
                    <!--</Button>-->
                </div>
            </div>

            <div>
                <img src="@/assets/images/iStock-117888895.jpg" alt="" class="object-cover w-full h-full" data-aos="fade-left" data-aos-duration="900" data-aos-delay="300" />
            </div>
        </section>



        <section class="grid lg:grid-cols-2 bg-unity-bluegreen xxl:max-w-screen-xl xxl:mx-auto">
            <div class="relative" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300">
                <iframe width="100%" height="100%" style="min-height: 300px" src="https://www.youtube.com/embed/AXZg-VLqv98" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
            <div class="flex items-center p-8 lg:px-16 lg:py-16">
                <div>
                    <h4 class="hdr-lead mb-3">Mossy Earth Partnership</h4>

                    <h2 class="font-extrabold mb-8 text-white">Mossy Earth helps people and businesses offset their carbon footprint</h2>
                    <p class="text-white font-light text-2xl">By planting forests and rewilding environmentally and socially impoverished land.</p>
                    <p class="text-white">Together, we will reforest the empty glens and hillsides of the Scottish Highlands by planting native trees in areas where the forest cannot regenerate on its own. This will not only reduce our carbon footprint but also restore ecosystems for the reintroduction of wildlife!</p>

                    <img src="@/assets/images/logo-mossy-earth.png" alt="" class="mt-5"/>
                </div>
            </div>
        </section>

        <HowItWorks @apply-url="$emit('apply-url')" />

        <Information />
        <CallBackForm />

    </main>
</template>

<script>
import Information from "@/components/Unity/Information";
import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import HowItWorks from "@/components/Unity/HowItWorks";
import Modal from "@/components/Unity/Modal";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "OurWayOfLife",
    components: {
        AprRepresentative,
        Modal,
        HowItWorks,
        CallBackForm,
        Information,
        Button
    },
    metaInfo: {
        title: 'Mossy Earth partnership',
    },
    data() {
        return {
            isModalVisible: false,
        };
    },
    methods: {

    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Our way of life',
            subTitle: ''
        })
    }
}
</script>

<style scoped>

</style>
