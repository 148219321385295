<template>
    <main class="about">
        <AprRepresentative />

        <section class="grid lg:grid-cols-2 relative xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">ALL TOGETHER. BETTER</h4>
                    <h2 class="font-extrabold mb-8">Find out if you are eligible for car finance in just 60 seconds.</h2>
                    <p>We’ll use our soft search to get you the best finance deal, without affecting your credit score, so you can buy your next car with confidence.</p>

                    <Button @click.native="$emit('apply-url')" theme="primaryLight" title="" class="mt-8">Get an instant quote</Button>
                </div>
            </div>
            <div class="hidden lg:block">
                <img src="@/assets/images/img-creditscore.jpg" alt="" class="object-cover w-full mx-auto h-full" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300"/>
            </div>
        </section>

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/img-stock-feed.jpg" alt="" class="object-cover w-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mb-3">Simple, quick, soft, credit, search</h4>
                    <h2 class="font-extrabold mb-8 text-white">Soft credit search without affecting your score</h2>
                    <p class="text-white">With our free, no obligation quote from Unity Auto Finance, you’ll find:</p>

                    <ul class="mt-4 list-outside ml-4 list-disc">
                        <li>The APR we can offer you</li>
                        <li>The monthly payments on over 200,000 used cars</li>
                        <li>Control of your car buying process</li>
                    </ul>

                    <Button @click.native="$emit('apply-url')" theme="outlineRed" title="Mossy Earth" class="mt-8">Apply</Button>
                </div>
            </div>
        </section>

        <section class="grid lg:grid-cols-2 relative xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">ALL TOGETHER. BETTER</h4>
                    <h2 class="font-extrabold mb-8">Why use our finance eligibility checker?</h2>
                    <ul class="mt-4 list-outside ml-4 list-disc">
                        <li>It’s a soft credit search - This means you can see it, but other lenders can’t</li>

                        <li>Free No Obligation Quote - There are no fees</li>

                        <li>Support if you need it. Our team is here to help. Call or chat with us online whenever you need.</li>

                        <li>Instant Decision - A fast & easy eligibility check. You can receive your results in under a minute!</li>

                        <li>PCP for all customers. Unlike most lenders, we can do Personal Contract Plans as well as Hire Purchase on eligible cars.</li>
                    </ul>

                    <Button @click.native="$emit('apply-url')" theme="primaryLight" title="" class="mt-8">Get an instant quote</Button>
                </div>
            </div>
            <div class="hidden lg:block">
                <img src="@/assets/images/img-quotes.jpg" alt="" class="object-cover w-full mx-auto h-full" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300"/>
            </div>
        </section>

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/img-personal-details.jpg" alt="" class="object-cover w-full h-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mb-3">Process</h4>
                    <h2 class="font-extrabold mb-8 text-white">How it works</h2>
                    <p class="text-white">With our free, no obligation quote from Unity Auto Finance, you’ll find:</p>

                    <ul class="my-4 list-outside ml-4 list-decimal text-white">
                        <li>Fill out a little personal info, including your name and address.</li>
                        <li>Receive your credit profile-based eligibility report for car financing.</li>
                        <li>Use your own APR, look for vehicles within your budget range </li>
                    </ul>

                    <p class="text-white">Our soft credit check will not impact your credit score.</p>

                    <p class="text-white">Car finance should be straightforward to arrange, affordable to repay, easy to manage. </p>

                    <Button @click.native="$emit('apply-url')" theme="outlineRed" title="Mossy Earth" class="mt-8">Apply</Button>
                </div>
            </div>
        </section>

        <Information />
        <CallBackForm />
    </main>
</template>

<script>
import Button from "@/components/Unity/Button";
import Information from "@/components/Unity/Information";
import CallBackForm from "@/components/CallBackForm";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "EligibilityChecker",
    components: {
        AprRepresentative,
        CallBackForm,
        Button,
        Information
    },
    metaInfo: {
        title: 'Car Finance Eligibility Checker ',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Car Finance Eligibility Checker',
            subTitle: 'Try our soft search now'
        })
    }
}
</script>

<style scoped>

</style>
