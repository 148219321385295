import axios from 'axios';

export class Cockpit {
    constructor() {
        this.cockpitAssetBaseUrl = process.env.VUE_APP_COCKPIT_ASSET_BASE
        this.cockpitBaseUrl = process.env.VUE_APP_COCKPIT_BASE
        this.apiKey = process.env.VUE_APP_COCKPIT_API_KEY
    }

    async getCollection(collection, params = {}) {
        return axios.post(`${this.cockpitBaseUrl}/api/collections/get/${collection}?token=${this.apiKey}`, {
            ...params
        }).then(r => {
            return r.data.entries && r.data.entries.length ? r.data.entries : []
        }).catch(e => {
            console.error(e)
            return []
        })
    }

    assetUrl(path = null) {
        return `${this.cockpitAssetBaseUrl}/${path}`
    }
}
