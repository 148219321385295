<template>
    <main class="news">
        <AprRepresentative />

        <div class="max-w-screen-xl mx-auto pt-24">
            <div v-if="loading">
                Loading...
            </div>
            <section class="posts" v-else>

                <!-- Featured post -->
                <article v-if="featuredPost !== null" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300">
                    <div class="grid grid-cols-1 md:grid-cols-2 my-12">
                        <a :href="$router.resolve({name: 'news-post', params: { slug: featuredPost.slug }}).href">
                            <div class="post-img featured" :style="{ backgroundImage: `url(${cockpit.assetUrl(featuredPost.image.path)})` }"></div>
                        </a>
                        <div class="post-content px-12 py-5">
                            <div class="post-date mb-5">{{ format(new Date(featuredPost.date), 'do MMMM yyyy') }}</div>
                            <h2 class="post-title font-bold mb-5">
                                <a :href="$router.resolve({name: 'news-post', params: { slug: featuredPost.slug }}).href">
                                    {{ featuredPost.title }}
                                </a>
                            </h2>
                            <div class="post-excerpt">{{ excerpt(featuredPost.excerpt) }}</div>
                            <div class="post-actions mt-5 text-right">
                                <Button :href="$router.resolve({name: 'news-post', params: { slug: featuredPost.slug }}).href" theme="outlineYellow">Read more</Button>
                            </div>
                        </div>
                    </div>
                </article>

                <!-- Rest of the posts -->
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">
                    <article v-for="(post, i) in news" :key="i" class="post" data-aos="fade-up" data-aos-duration="500" :data-aos-offset="100">
                        <a :href="$router.resolve({name: 'news-post', params: { slug: post.slug }}).href">
                            <div class="post-img" :style="{ backgroundImage: `url(${cockpit.assetUrl(post.image.path)})` }"></div>
                        </a>
                        <div class="px-12 py-5">
                            <div class="post-date mt-5 mb-3">{{ format(new Date(post.date), 'do MMMM yyyy') }}</div>
                            <h2 class="post-title mb-5 font-bold">
                                <a :href="$router.resolve({name: 'news-post', params: { slug: post.slug }}).href">
                                    {{ post.title }}
                                </a>
                            </h2>
                            <div class="post-excerpt">{{ excerpt(post.excerpt) }}</div>
                            <div class="post-actions mt-5">
                                <Button :href="$router.resolve({name: 'news-post', params: { slug: post.slug }}).href" theme="outlineYellow">Read more</Button>
                            </div>
                        </div>
                    </article>
                </div>


                <div class="text-center my-32">
                    <div v-if="loadedAllPosts" class="text-xl text-gray-600">You're all caught up</div>

                    <div v-else @click="getNews()">
                        <Button :theme="loadingMore ? 'loading' : 'secondary'">Load more</Button>
                    </div>
                </div>
            </section>
        </div>

        <CallBackForm />
    </main>
</template>

<script>
import { format } from 'date-fns'
import Button from "@/components/Unity/Button";
import { Cockpit } from "@/lib/Cockpit";
import CallBackForm from "@/components/CallBackForm";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "News",

    components: {AprRepresentative, CallBackForm, Button},

    metaInfo: {
        title: 'Latest news',
    },

    data() {
        return {
            format,
            cockpit: new Cockpit(),
            loading: false,
            loadingMore: false,
            news: [],
            featuredPost: null,
            limit: 6,
            page: 1,
            loadedAllPosts: false
        }
    },

    mounted() {
        this.$emit('update-banner-title', {
            title: 'Latest news',
        })

        this.getNews()
        this.getFeaturedPost()
    },

    methods: {
        /**
         * Load news posts
         * @returns {Promise<void>}
         */
        async getNews() {
            // Enable loading
            if(this.page > 1) {
                this.loadingMore = true
                this.loading = false
            } else {
                this.loadingMore = false
                this.loading = true
            }

            // Fetch posts
            const posts = await this.cockpit.getCollection("unity_blog_posts", {
                sort: {
                    date: -1
                },
                filter: {
                    featured: false,
                },
                limit: this.limit,
                skip: this.page === 1 ? 0 : this.limit * this.page - this.limit
            })

            // Did we get any posts back?
            if(posts.length) {
                if(this.page > 1) {
                    // Append the results
                    this.news = this.news.concat(posts)
                } else {
                    // Replace the results
                    this.news = posts
                }

                this.page += 1
            } else {
                // No posts, we've reached the end
                this.loadedAllPosts = true
            }

            // Disable loading
            this.loading = false
            this.loadingMore = false
        },

        async getFeaturedPost() {
            const featured = await this.cockpit.getCollection("unity_blog_posts", {
                sort: {
                    date: -1,
                },
                filter: {
                    featured: true,
                },
                limit: 1
            })

            if(featured.length > 0) {
                this.featuredPost = featured[0]
            }
        },

        /**
         * Trims a string to a specified length
         * @param val
         * @param len
         * @param suffix
         * @returns {string}
         */
        excerpt(val, len = 200, suffix = "...") {
            if(val.length > len) {
                return val.substr(0, len).trim()+suffix
            }
        },
    }
}
</script>

<style scoped lang="scss">
.posts {
    .post-img {
        height: 300px;
        background-position: center;
        background-size: cover;
    }

    .post-date {
        color: #FD6A4E;
        text-transform: uppercase;
        font-weight: bold;
    }
}
</style>
