<template>

    <div class="flex-col justify-between max-w-screen-xl lg:w-3/4 mx-auto text-center px-8 sm:px-6">
        <!-- TrustBox widget - Micro Combo -->
        <div class="w-full py-8">
            <div class="trustpilot-widget mx-auto" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="62b1db1307293511dd2bad53" data-style-height="20px" data-style-width="100%" data-theme="light">
                <a href="https://www.trustpilot.com/review/unityauto.finance" target="_blank" rel="noopener">Trustpilot</a>
            </div>
        </div>
        <!-- End TrustBox widget -->

        <p class="mx-auto mb-8 text-sm text-unity-text-grey">Representative Example APR 30.9% - Hire Purchase: Cash Price £10,000.00, Deposit £0, borrowing £10,000.00 over 5 years at 30.9% Representative APR (fixed). 59 monthly payments of £306.65 followed by followed by a final payment of £316.65 which includes a £10 option to purchase fee. Total cost of credit of £8409.00 and total amount payable of £18,409.00. Finance subject to status 21+ only.</p>
    </div>
</template>

<script>
export default {
    name: "AprRepresentative"
}
</script>

<style scoped>

</style>
