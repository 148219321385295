<template>
    <main class="">
        <AprRepresentative />

        <section class="pb-20 pt-10 px-8 sm:px-6">
            <div class="flex-col justify-between max-w-screen-xl mx-auto text-center">
                <h4 class="hdr-lead">ALTOGETHER A MORE ETHICAL LENDER</h4>
                <h2 class="sm:text-4xl md:text-5xl font-extrabold mb-6">What makes us different</h2>
                <p class="lg:w-1/2 mx-auto text-xl font-light">Unity offers a more sustainable way to finance your car, so you can reduce your impact on the environment</p>
            </div>
        </section>


        <Process @apply-url="$emit('apply-url')" />
        <MossyEarth />
        <Information />

	    <section class="bg-unity-yellow">
		    <div class="flex-col justify-between max-w-screen-xl mx-auto text-center  pt-6 pb-4 px-8">
			    <p class="mb-0 text-black font-bold">We are aware of scams involving individuals being offered loans for an advanced upfront fee by organisations posing as a representative of financial services organisations. At Unity Auto Finance Limited we do not charge up-front fees for our loans and would not approach you directly in this way. If you think you have been a victim of such a scam, please contact your bank immediately and report it to <a href="https://www.actionfraud.police.uk/" target="_blank" title="Action Fraud" class="underline">Action Fraud</a></p>
		    </div>
	    </section>

	    <section>
		    <div class="flex-col justify-between max-w-screen-xl mx-auto text-center pt-6 pb-4 px-8">
			    <p>If you’re experiencing financial distress, rest assured that we’re here to assist you. Taking that first step is crucial, and reaching out to us is the most significant move you can make. Our dedicated team collaborates with <a href="https://www.payplan.com/" target="_blank" class="underline">PayPlan</a>, an organisation specialising in helping individuals navigate financial challenges.</p>
			    <p><strong>Here’s how we can support you:</strong><br />Get in Touch: Reach out to us promptly via call, email, or chat. Don’t hesitate—the sooner you connect, the easier the process becomes. Guidance from Payplan: We work closely with Payplan, who will guide you through this journey. They understand the complexities of financial difficulties and can provide tailored advice and solutions.</p>
			    <p><strong>Your Well-Being Matters:</strong><br />Our commitment is to your well-being. Contact us now, and let’s work together to find the best path forward.</p>
		    </div>
	    </section>

        <CallBackForm />
    </main>
</template>

<script>
import Process from "@/components/Unity/Process";
import MossyEarth from "@/components/Unity/MossyEarth";
import Information from "@/components/Unity/Information";
import CallBackForm from "@/components/CallBackForm";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "Home.vue",
    components: {
        AprRepresentative,
        CallBackForm,
        Information,
        MossyEarth,
        Process
    },

    metaInfo: {
        title: 'Ethical car finance lender',
    },

    data() {
        return {

        }
    },

    mounted() {
        this.$emit('update-banner-title', {
            title: 'Car finance with a conscience',
            subTitle: 'Save money, save time, help our planet.'
        })
    }
}
</script>
