<template>
    <main class="pcp-explained">

        <AprRepresentative />

        <section class="grid lg:grid-cols-2 pt-12 md:pt-0  xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">We provide PCP finance</h4>
                    <h2 class="font-extrabold mb-8">So how does PCP finance work?</h2>
                    <p>If you’re looking for car finance at a lower fixed monthly payment than a traditional car loan, then a PCP (Personal Contract Purchase) – is probably what you’re after. PCP is one of the most popular car finance products on the market – especially for buyers looking to change their car on a regular basis.</p>

                    <p>PCP is based on a Hire Purchase agreement, which lets you pay for your vehicle over a fixed period  – usually two to four years. The difference with a PCP is that a certain amount of the loan - based on the vehicle’s anticipated future value - is deferred until the end, which lowers the monthly payments.</p>

                    <p>Starting with a flexible deposit - usually 10% to 20% - you’ll pay a fixed monthly repayment each month over the course of the agreement. At the end of this term, you’ll have three options:</p>
                    <ul class="mt-4 check-list-dark">
                        <li>Return the car with nothing further to pay (the car must be in good condition and within your chosen mileage limit)</li>
                        <li>Pay off the outstanding GMFV, and keep the vehicle.</li>
                        <li>Part exchange your car and use any outstanding equity against your next car purchase.</li>
                        <li>There is a mileage restriction, which if exceeded will incur excess mileage charges</li>
                        <li>You do not own the vehicle until all of the payments have been made</li>
                    </ul>

                    <!--<Button @click.native="$emit('apply-url')" theme="primary" title="Get An Instant Secure Quote" class="mt-8">-->
                    <!--    Get an instant quote-->
                    <!--</Button>-->
                </div>
            </div>

            <div>
                <img src="@/assets/images/iStock-1319763191.jpg" alt="" class="shrink object-cover w-full h-full" data-aos="fade-left" data-aos-duration="900" data-aos-delay="300" />
            </div>
        </section>

        <WhyPcp @apply-url="$emit('apply-url')" />

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/iStock-639949218-1.jpg" alt="" class="object-cover w-full h-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mb-3">PCP Agreement</h4>
                    <h3 class="font-extrabold mb-8 text-white">At the end of a PCP agreement the user has three options:</h3>

                    <ul class="mb-8 text-white check-list">
                        <li>Pay the GMFV and keep the car</li>
                        <li>Trade the car in and use any equity towards their next car purchase</li>
                        <li>Hand the car back with nothing more to pay</li>
                    </ul>

                </div>
            </div>
        </section>

        <Information />
        <CallBackForm />

    </main>
</template>

<script>
import Button from "@/components/Unity/Button";
import Information from "@/components/Unity/Information";
import WhyPcp from "@/components/Unity/WhyPcp";
import CallBackForm from "@/components/CallBackForm";

import imageOne from '@/assets/images/iStock-1319763191.jpg'
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "PcpExplained",
    components: {
        AprRepresentative,
        CallBackForm,
        Button,
        Information,
        WhyPcp
    },
    metaInfo: {
        title: 'PCP explained',
    },
    data() {
        return {
            imageOne
        }
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'PCP Explained',
            subTitle: ''
        })
    }
}
</script>

<style scoped lang="scss">

</style>
