<template>
    <section class="bg-gray-50 py-12 md:py-24 px-8 sm:px-6">
        <div class="flex-col justify-between max-w-screen-xl mx-auto text-center">
            <h4 class="hdr-lead mb-3">Pro's of Hp finance</h4>
            <h2 class="font-extrabold mb-16">Why choose HP finance?</h2>

            <div class="grid md:grid-cols-2 lg:grid-cols-4 space-x-4 xl:space-x-8">
                <div v-for="(item, i) in items" :key="i" class="px-8 flex flex-wrap content-start space-y-8" data-aos="fade-up" data-aos-duration="500" :data-aos-offset="item.offset">
                    <img :src="require(`@/assets/images/${item.img}`)" :alt="item.title" class="shrink h-52 mx-auto" />
                    <div class="w-full">
                        <h4 class="hdr-lead mb-3">{{ item.title }}</h4>
                        <p>{{ item.desc }}</p>
                    </div>
                </div>
            </div>

            <!--<div class="flex justify-center items-center py-8 mt-8">-->
            <!--    <Button @click.native="$emit('apply-url')" theme="primary" title="Get An Instant Secure Quote" class="mt-8">-->
            <!--        Get an instant quote-->
            <!--    </Button>-->
            <!--</div>-->
        </div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
export default {
    name: "WhyHp",
    components: {Button},
    data() {
        return {
            items: [
                {
                    title: 'Simple & straightforward',
                    desc: 'The simplest form of car finance',
                    img: 'simple-car-finance.svg',
                    offset: '50'
                },
                {
                    title: 'Fixed monthly cost ',
                    desc: 'Predictable monthly payments',
                    img: 'monthly-cost.svg',
                    offset: '130'
                },
                {
                    title: 'Security ',
                    desc: 'Finance is secured on the car',
                    img: 'car-secure.svg',
                    offset: '250'
                },
                {
                    title: 'Settle when you like',
                    desc: 'No early settlement penalty',
                    img: 'agreement.svg',
                    offset: '310'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
