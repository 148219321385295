<template>
    <div class="accordion">
        <div v-on:click="isOpen = !isOpen" class="border-b border-gray-400 border-1 py-3 cursor-pointer flex items-center">
            <div class="text-lg mb-0 text-unity-text-grey">{{ title }}</div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="icon ml-auto" :class="isOpen ? 'is-active' : ''">
                <path fill="#EC7358" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"/>
            </svg>
        </div>
        <slide-up-down :active="isOpen" :duration="250">
            <div class="py-5">
                <slot></slot>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
    name: 'Accordion',

    components: {
        SlideUpDown
    },

    props: {
        title: String,
    },

    data: () => {
        return {
            isOpen: false
        }
    }
}
</script>


<style scoped lang="scss">
.accordion {
    .icon {
        display: block;
        width: 30px;
        height: 30px;
        transition: transform 0.2s ease;
        transform: rotate(0deg);

        &.is-active{
            transform: rotate(180deg) !important;
        }
    }
}
</style>
