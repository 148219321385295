<template>
    <main class="about">
        <AprRepresentative />

        <section class="grid lg:grid-cols-2 relative xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">ALL TOGETHER. BETTER</h4>
                    <h2 class="font-extrabold mb-8">How does it work?</h2>
                    <p>We’re a new direct lender. We’ll help you get a quick car finance decision using our soft search and give you a fair rate. We’ve over 200,000 listed from many of the best UK car dealers, so you can find the right car for you.</p>

                    <Button @click.native="$emit('apply-url')" theme="primaryLight" title="" class="mt-4">Get an instant quote</Button>
                </div>
            </div>
            <div class="hidden lg:block">
                <img src="@/assets/images/img-stock-feed.jpg" alt="" class="object-cover w-full mx-auto h-full" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300"/>
            </div>

        </section>

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/img-creditscore.jpg" alt="" class="object-cover w-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mb-3">what we do best</h4>
                    <h2 class="font-extrabold mb-8 text-white">What does Unity Car Finance offer?</h2>

                    <p class="text-white">With Unity, it’s personal. As an ethical lender, we believe in treating every customer equally, so we don't just offer financing to those with perfect credit at Unity Auto Finance. We let you know the rate you will be approved at up front, and just need to check your ID and that you can afford the payments  so you can buy the car or van of your choice. Whatever your circumstances, give us a try and we’ll do our best to meet your needs.</p>

                    <Button @click.native="$emit('apply-url')" theme="outlineRed" title="Mossy Earth" class="mt-8">Apply</Button>
                </div>
            </div>
        </section>

        <section class="grid lg:grid-cols-2 relative xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 py-16 lg:px-16 xxl:pl-0">
                <div>
                    <!--<h4 class="hdr-lead mb-3">ALL TOGETHER. BETTER</h4>-->
                    <h2 class="font-extrabold mb-8">Why choose Unity Auto Finance?</h2>

                    <p>We finance and underwrite your agreement ourselves. We are a direct lender and not a broker, backed by £300 million + of funding to lend to UK consumers.</p>

                    <p>Your credit score won't be impacted if you just apply with us.  We employ a soft search meaning, even if you are unsuccessful, it won't affect your credit report. You’ll be able to see it, but other lenders can’t until you complete your deal.</p>

                    <p>Those with less-than-perfect credit can apply for loans from us. You must be at least 21 years old, a resident of the UK, and able to pay back the loan.</p>

                    <p>You’ll get a pretty instant decision. We approve loans in principle (subject to affordability and ID checks) in around 2 minutes and can get you into your next car in double quick time!</p>

                    <p>As a responsible & ethical direct lender, we can’t guarantee that we will approve every applicant for car finance, but we will always endeavour to get you accepted if we can.</p>

                    <Button @click.native="$emit('apply-url')" theme="primaryLight" title="" class="mt-8">Get an instant quote</Button>
                </div>
            </div>
            <div class="hidden lg:block">
                <img src="@/assets/images/iStock-1179321975.jpg" alt="" class="object-cover w-full mx-auto h-full" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300"/>
            </div>

        </section>

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/img-dashboard-steps.jpg" alt="" class="object-cover w-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <!--<h4 class="hdr-lead mb-3">what we do best</h4>-->
                    <h2 class="font-extrabold mb-8 text-white">What happens after I apply?</h2>
                    <p class="text-white">Decisions - We’ll review your application and you will see the decision online in your Unity dashboard almost instantly. Sometimes we need more time to complete our checks, but we’ll tell you if we do. We endeavour to complete all decisions within 45 minutes during business hours.
                    </p>

                    <p class="text-white">Choose a Vehicle- You’ll get access to our online marketplace with over 8,000 dealers & 200,000+ cars to find a car that suits your needs. Browse the full range and find the perfect vehicle and tailor the deposit and term to your needs. We’ll give you a call to check in every now and then, to make sure you are looked after. </p>

                    <p class="text-white">E-sign - You can sign your paperwork online from the comfort of your home or office.</p>

                    <p class="text-white">Drive- Whether Delivered or collected, Enjoy Your New Car! We’ll pay the dealer and you are good to go</p>

                    <h4 class="text-unity-red">Is it all online?</h4>
                    <p class="text-white">No. Whilst you can do pretty much the whole process online, a lot of customers want help and support and our team will look after you via phone, email, SMS or via our online chat. We’ll usually check in with you by phone after you apply to make sure you know what the next steps are.</p>

                    <Button @click.native="$emit('apply-url')" theme="outlineRed" title="Mossy Earth" class="mt-8">Apply</Button>
                </div>
            </div>
        </section>

        <Information />
        <CallBackForm />
    </main>
</template>

<script>
import Button from "@/components/Unity/Button";
import Information from "@/components/Unity/Information";
import CallBackForm from "@/components/CallBackForm";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "CarFinance",
    components: {
        AprRepresentative,
        CallBackForm,
        Button,
        Information
    },
    metaInfo: {
        title: 'Flexible, personalised used car finance',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Flexible, personalised used car finance',
            subTitle: 'We are the lender, so you cut out the middleman'
        })
    }
}
</script>

<style scoped>

</style>
