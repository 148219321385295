<template>
    <main class="about">
        <AprRepresentative />

        <section class="grid lg:grid-cols-2 relative xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">ALL TOGETHER. BETTER</h4>
                    <h2 class="font-extrabold mb-8">Car Finance With a Conscience</h2>
                    <p>Unity offers car finance with a conscience. We'll give you an instant tailored car finance quotation based on your unique Uniscore TM soft credit search. You can use our unique car finance calculator to work out the best figures for either Personal Contract Purchase (PCP) or Hire Purchase (HP) agreements, and then choose from thousands of cars on our website and apply online when you're ready. We’re a lender not a broker, so there are no middlemen.</p>

                    <p>When you finance a car through us, we'll plant a tree in the Scottish Highlands on your behalf to help our environment.</p>

                    <Button @click.native="$emit('apply-url')" theme="primaryLight" title="" class="mt-8">Get an instant quote</Button>
                </div>
            </div>
            <div class="hidden lg:block">
                <img src="@/assets/images/quote-phone-half.png" alt="" class="object-cover w-1/2 mx-auto h-full" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300"/>
            </div>
            <div class="shape shape--circle" data-aos="fade-right" data-aos-delay="250"></div>
        </section>

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/iStock-1161321111.jpg" alt="" class="object-cover w-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mb-3">what we do best</h4>
                    <h2 class="font-extrabold mb-8 text-white">Making car finance affordable, fast and environmentally friendly</h2>
                    <p class="text-white">Unity has teamed up with Mossy Earth, who help people and businesses offset their carbon footprint by planting forests and rewilding environmentally and socially impoverished land. Unity Auto Finance is a carbon-neutral business.</p>

                    <p class="text-white">We’re passionate about the environment and we're taking steps to ensure our business is as sustainable as possible.</p>

                    <Button @click.native="$router.push({name: 'OurWayOfLife'})" theme="outlineRed" title="Mossy Earth" class="mt-8">Find out more</Button>
                </div>
            </div>
        </section>

        <section class="relative text-center section-team xxl:max-w-screen-xl xxl:mx-auto">
            <img src="@/assets/images/team-photo-01.jpg" alt="" class="object-cover w-full h-full" data-aos="zoom-out-up" data-aos-duration="900" data-aos-delay="300" />
            <div class="z-10 absolute top-0 flex justify-center w-full pt-12">
                <div>
                    <h4 class="hdr-lead lg:mt-8 mb-3">Our people</h4>
                    <h2 class="hidden lg:block font-extrabold mb-8 text-white">All together. Different.</h2>
                    <p class="hidden lg:block sm:max-w-xl mx-auto text-2xl text-white">At Unity we’re working together to be different. We want to be more innovative, more ethical and more responsible than other lenders. This is what motivates and unites the Unity team.</p>
                </div>
            </div>
        </section>

        <section class="grid lg:grid-cols-2 relative bg-gray-100 xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mb-3">About us</h4>
                    <h2 class="font-extrabold mb-8">Part of DSG Finance Group</h2>
                    <p>Unity Auto Finance is part of the DSG Finance Group, a collective of forward-thinking brands and investments specialising in UK motor finance lending and technology for the last 30 years. We have over 200 staff based in Manchester and Leeds, and are a trusted partner of the UK’s best motor dealers.</p>
                </div>
            </div>
            <div class="hidden lg:block">
                <img src="@/assets/images/team-photo.jpg" alt="" class="object-cover w-full h-full" data-aos="fade-left" data-aos-duration="900" data-aos-delay="300" />
            </div>

        </section>

        <Information />
        <CallBackForm />

    </main>
</template>

<script>
import Button from "@/components/Unity/Button";
import Information from "@/components/Unity/Information";
import CallBackForm from "@/components/CallBackForm";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "about",
    components: {
        AprRepresentative,
        CallBackForm,
        Button,
        Information
    },
    metaInfo: {
        title: 'About us',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'Save money, save time, help our planet',
            subTitle: 'What we do matters. The environment and our community matter, too. Let\'s make it better, together'
        })
    }
}
</script>

<style scoped lang="scss">
.section-team {
    &:before {
        @apply absolute top-0 left-0 w-full;

        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(0,0,0,0.7682423311121324) 0%, rgba(0,0,0,0) 100%);
        content: '';
        height: 100%;
        z-index: 1;
    }
}

.shape {
    width: 226px;
    height: 226px;
    position: absolute;
    right: 49%;
    left: 48%;
    background-image: url("~@/assets/images/arrow--left.svg");
    background-size: cover;
    background-position: center;
    z-index: 2;

    &--circle {
        width: 61px;
        height: 123px;
        bottom: -60px;
        top: initial;
        background-image: url("~@/assets/images/half-circle--yellow--right.svg");

        @media (max-width: 860px) {
            width: 60px;
            height: 120px;
        }

        @media (max-width: 600px) {
            width: 45px;
            height: 90px;
            left: 20px;
        }
    }
}
</style>
