<template>
    <section class="bg-unity-bluegreen py-32 px-8 sm:px-6 relative" data-aos="flip-up" data-aos-offset="300">
        <div class="flex-col justify-between max-w-screen-xl mx-auto text-white">
            <div class="grid lg:grid-cols-2">
                <div class="flex items-center order-first">
                    <div>
                        <h4 class="hdr-lead mt-8 mb-3">Mossy earth partnership</h4>
                        <h2 class="text-white font-extrabold">We’re passionate about the environment and taking steps to ensure our business is as sustainable as possible</h2>
                        <div class="grid md:grid-cols-2 space-x-8">
                            <Button @click.native="$router.push({name: 'OurWayOfLife'})" theme="outlineYellow" title="" class="hidden md:block mt-8">Find out more</Button>
                            <div class="justify-center items-center hidden md:flex">
                                <img src="@/assets/images/logo-mossy-earth.png" alt="Mossy Earth Logo" class="w-56 h-auto" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full md:flex justify-center mt-12 order-last md:order-first">
                    <span class="pulse mx-auto">
                        <img src="@/assets/images/mossy-earth.svg" alt="Mossy Earth Partnership" class="" />
                    </span>

                    <div class="block md:hidden">
                        <Button @click.native="$router.push({name: 'OurWayOfLife'})" theme="outlineYellow" title="Mossy Earth" class="mt-12 w-full">Find out more</Button>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape shape--circle" data-aos="fade-right" data-aos-delay="300"></div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
export default {
    name: "MossyEarth",
    components: {
        Button
    },
}
</script>

<style scoped lang="scss">
.pulse {
    @apply block rounded-full cursor-pointer;

    width: 15rem;
    height: 15rem;
    background: #306C77;
    box-shadow: 0 0 0 rgba(49,144,161, 0.4);
    animation: pulse 2s infinite;

    &:hover {
        animation: none;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(49,144,161, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 60px rgba(49,144,161, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(49,144,161, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(49,144,161, 0.4);
        box-shadow: 0 0 0 0 rgba(49,144,161, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 60px rgba(49,144,161, 0);
        box-shadow: 0 0 0 60px rgba(49,144,161, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(49,144,161, 0);
        box-shadow: 0 0 0 0 rgba(49,144,161, 0);
    }
}

.shape {
    width: 226px;
    height: 226px;
    position: absolute;
    left: 2rem;
    bottom: -16px * 9.5;
    background-image: url("~@/assets/images/arrow--left.svg");
    background-size: cover;
    background-position: center;
    z-index: 2;

    &--circle {
        width: 52px;
        height: 103px;
        top: 420px;
        bottom: auto;
        background-image: url("~@/assets/images/half-circle--yellow--right.svg");

        @media (max-width: 860px) {
            top: 673px;
        }

        @media (max-width: 600px) {
            top: 708px;
        }
    }
}

</style>
