<template>
    <section class="bg-gray-50 py-12 md:py-24 px-8 sm:px-6">
        <div class="flex-col justify-between max-w-screen-xl mx-auto text-center">
            <h4 class="hdr-lead mb-3">Benefits</h4>
            <h2 class="font-extrabold mb-8">Our products</h2>

            <div class="grid md:grid-cols-2 lg:grid-cols-4 space-x-4 xl:space-x-8">
                <div v-for="(item, i) in items" :key="i" class="px-8 flex flex-wrap content-between">
                    <img :src="require(`@/assets/images/${item.img}`)" :alt="item.title" class="shrink h-52 mx-auto" />
                    <div>
                        <h4 class="hdr-lead mt-8 mb-3">{{ item.title }}</h4>
                        <p>{{ item.desc }}</p>
                    </div>
                </div>
            </div>

            <p class="mt-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a mi metus. Sed blandit ante quis tortor condimentum, eget mollis dolor sagittis.</p>

            <div class="flex justify-center items-center py-8 mt-8">
                <Button @click.native="$emit('apply-url')" theme="primaryLight" title="Get a quote" target="_blank" class="mx-auto">Get a quote</Button>
            </div>
        </div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
export default {
    name: "OurProducts",
    components: {Button},
    data() {
        return {
            items: [
                {
                    title: 'Lorem ipsum',
                    desc: 'A simple way to finance a vehicle purchase.',
                    img: 'planet-people-first.svg'
                },
                {
                    title: 'Lorem ipsum',
                    desc: 'Flexible deposit options at the start.',
                    img: 'change-for-good.svg'
                },
                {
                    title: 'Lorem ipsum',
                    desc: 'A choice of agreement length based on your circumstances.',
                    img: 'finvirotech.svg'
                },
                {
                    title: 'Lorem ipsum',
                    desc: 'Stability of fixed monthly repayments.',
                    img: 'finvirotech.svg'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
