<template>
	<div class="flex items-center justify-center">
		<button @click="drawer" aria-label="Open Menu" class="nav-buttons w-10 h-10 hover:text-unity-red text-white flex items-center justify-center text-sm rounded-full focus:outline-none w-md md:mr-8">
			<svg class="fill-current" style="width:22px;height:22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
				<title>Menu</title>
				<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
			</svg>
		</button>

		<transition
			enter-class="opacity-0"
			enter-active-class="ease-out transition duration-150"
			enter-to-class="opacity-100"
			leave-class="opacity-100"
			leave-active-class="ease-in-out transition duration-150"
			leave-to-class="opacity-0"
		>
			<div @keydown.esc="isOpen = false" v-show="isOpen" class="z-10 fixed inset-0 transition-opacity">
				<div @click="isOpen = false" class="absolute inset-0 bg-black opacity-75" tabindex="0"></div>
			</div>
		</transition>

		<!-- Sidebar -->
		<aside class="transform top-0 right-0 w-3/4 md:w-4/12 lg:w-1/4 xl:w-2/6 bg-unity-dark-grey fixed h-full z-30 overflow-auto ease-in-out transition-all duration-300 flex"
				:class="!isOpen ? 'translate-x-full' : '-translate-x-0'">
			<div class="relative flex-1 flex flex-col w-full pt-5 bg-unity-dark-grey">
                <div class="pb-5 flex-shrink-0 flex items-center border-b border-gray-700 px-6 md:px-8">
                    <div class="ml-auto">
                        <img src="@/assets/images/logo-unity-grey.svg" alt="Unity" class="logo cursor-pointer opacity-50 mx-auto" @click="$router.push({ name: 'home' })" />
                    </div>
                    <div @click="isOpen = false" class="absolute cursor-pointer  text-white">
                        <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </div>
                </div>
				<div class="overflow-y-auto p-6 md:p-8">
                    <nav>
                        <ul class="space-y-0 mb-8">
                            <li class="menu-item relative" v-for="(link, index) in navigation" :key="index">
                                <router-link :to="{ name: link.route}" class="uppercase menu-link w-full border-b border-gray-700 group flex items-center py-3 text-base leading-6 font-medium focus:outline-none text-white transition ease-in-out duration-300" :title="link.label">
                                     {{ link.name}}
                                </router-link>
                            </li>
                            <li class="menu-item relative">
                                <a href="#callback" class="cursor-pointer uppercase menu-link w-full border-b border-gray-700 group flex items-center py-3 text-base leading-6 font-medium focus:outline-none text-white transition ease-in-out duration-300">Contact us</a>
                            </li>
                        </ul>
                        <div class="grid sm:grid-cols-2 space-y-4 sm:space-y-0 sm:space-x-4 py-8 md:px-2">
                            <Button @click.native="$emit('apply-url')" theme="primary" title="Apply for finance" class="w-full">Apply</Button>
                            <Button theme="outlineYellow" title="Sign to your dashboard" class="w-full" @click.native="$emit('dashboard-login')">Sign In</Button>
                        </div>
                    </nav>

				</div>
			</div>
		</aside>
	</div>
</template>
<script>
import Button from '@/components/Unity/Button'

export default {
	name: "Sidebar",
	components: {
        Button
	},
	watch: {
		$route(to) {
			// Using watch and manual assignment (as opposed to using computed) so when you click
			// on a different item in the menu you don't have to wait for the route to change.
			// This avoids the new focused menu item + current menu item being both highlighted
			this.currentRouteName = to.name

            // Close the menu
            this.isOpen = false
		}
	},

    props: {
        webLinks: {
            type: Array,
            default: () => [],
        }
    },

	data() {
		return {
			isOpen: false,
			currentRouteName: this.$route.name,
            currentChild: -1,
            navigation: [
                { name: 'Our Way Of Life', route: 'OurWayOfLife' },
                { name: 'Hp explained', route: 'hp-explained' },
                { name: 'Faqs', route: 'faqs' },
                { name: 'About us', route: 'about' },
                { name: 'News', route: 'news' },
            ]
		}
	},

	methods: {
		drawer() {
			this.isOpen = !this.isOpen;
		},

        scrollBehavior: function (to) {
            if (to.hash) {
                return {
                    selector: to.hash
                }
            }
        },
	}
}
</script>

<style scoped lang="scss">
    .logo {
        width: 70px;
    }

    .menu-item {
        .menu-link {
            position: relative;

            &:after {
                @apply absolute left-0 bottom-0 block w-0;

                content: "";
                height: 1px;
                background: #EC7358;
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
            }

            &:hover {
                &:after {
                    @apply left-0 w-full;
                }
            }
        }
    }
</style>
