<template>
    <main class="not-found">
        <div class="flex items-center p-8 lg:px-16">
            <div>
                <h4 class="hdr-lead mb-3">Error</h4>
                <h2 class="font-extrabold mb-8">Oops! you weren't suppose to see this</h2>

                <p>The page you are looking for no longer exists.</p>
                <p>Resturn to the <router-link to="/" title="home page" class="underline text-unity-red hover:no-underline">home page</router-link> and remember: you haven't seen anything.</p>
            </div>
        </div>
	</main>
</template>
<script>
    import Button from "@/components/Unity/Button";
    import Information from "@/components/Unity/Information";
    import CallBackForm from "@/components/CallBackForm";

    export default {
        name: "NotFound",
        components: {
            CallBackForm,
            Button,
            Information
        },
        metaInfo: {
            title: '404 page not found',
        },
        mounted() {
            this.$emit('update-banner-title', {
                title: '404 page not found',
                subTitle: ''
            })
        }
    }
</script>
