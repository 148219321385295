<template>
    <main>
        <AprRepresentative />

        <div class="faqs max-w-screen-xl mx-auto py-20 px-8 sm:px-6 xxl:px-0">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
                <div v-for="(faq, i) in faqs" :key="i">
                    <dt class="text-xl leading-6 font-bold text-flow-blue">
                        {{ faq.title }}
                    </dt>
                    <dd class="mt-2 text-base text-gray-600" v-html="faq.copy"></dd>
                </div>
            </dl>

	        <div class="bg-unity-yellow py-6 px-8 mt-6">
	        <p class="mb-0 text-black font-bold">We are aware of scams involving individuals being offered loans for an advanced upfront fee by organisations posing as a representative of financial services organisations. At Unity Auto Finance Limited we do not charge up-front fees for our loans and would not approach you directly in this way. If you think you have been a victim of such a scam, please contact your bank immediately and report it to <a href="https://www.actionfraud.police.uk/" target="_blank" title="Action Fraud" class="underline">Action Fraud</a></p>
	        </div>
        </div>

        <CallBackForm />
    </main>
</template>

<script>

import CallBackForm from "@/components/CallBackForm";
import Button from "@/components/Unity/Button";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "Faqs",
    components: {
        AprRepresentative,
        CallBackForm,
        Button
    },
    metaInfo: {
        title: 'Frequently asked questions',
    },
    data() {
        return {
            faqs: [
	            {
		            title: 'What is HP?',
		            copy: '<p>A hire purchase (HP) agreement is a fixed cost, fixed period loan of money to purchase goods. It is an agreement where the finance company hires the vehicle to you for an agreed period at an agreed monthly sum. You can gain ownership (title) of the vehicle by making all instalments and paying an additional sum called the Option to Purchase Fee at the end of the agreement.</p><p>With hire purchase agreements, there is no annual mileage limit, and you have the option to make lump sum payments or to settle the agreement in full at any time.</p>',
	            },
	            {
		            title: 'Do I need to pay a deposit to get the loan/finance?',
		            copy: '<p>No you do not need to pay a deposit to get a car loan/finance.</p>',
	            },
	            {
		            title: 'How long will it take to get the finance approved and take delivery of the vehicle?',
		            copy: "<p>Generally within 48 hours.</p>",
	            },
	            {
		            title: 'Who are the FCA (Financial Conduct Authority)?',
		            copy: '<p>The <strong>Financial Conduct Authority (FCA)</strong> is the regulatory body responsible for overseeing financial services firms and financial markets in the <strong>United Kingdom</strong>. The FCA\'s primary functions include <strong>protecting consumers</strong>, maintaining industry stability, and promoting healthy competition among financial service providers.</p>',
	            },
	            {
		            title: 'What is Open Banking?',
		            copy: '<p><a href="https://share.synthesia.io/8b741d2d-4809-4b8e-a9ab-ac070d2fbbc8" target="_blank" class="underline">Click here</a> to watch our short video.</p>',
	            },
	            {
		            title: 'Can I buy my vehicle privately, without going to a dealer?',
		            copy: "<p>Simple answer is NO. We only work with reputable dealers who are FCA registered. This ensures a secure and transparent car buying process.</p>",
	            },
	            {
		            title: 'What is eSign?',
		            copy: `<p>Once you have been approved and are happy to proceed with the loan you will need to sign legally binding documentation. This will be done be electronic signature which is safe and secure. You will need to have your full driving licence, a debit or credit card that is registered at your current address (no payment will be taken) and a proof of address at the ready.</p>`,
	            },
	            {
		            title: 'What does APR stand for?',
		            copy: '<p>APR stands for Annual Percentage Rate, it relates to the amount of interest and charges you have to pay. The Consumer Credit Act specifies a formula for calculating this figure, which must be used by all lenders. This is done to allow you to make a fair comparison between various offers of credit. Further information on how APR is calculated is available from the Financial Conduct Authority (FCA).</p>',
	            },
	            {
		            title: 'When will my first payment fall due?',
		            copy: '<p>Your first payment is usually one month from the date of signing your Agreement. However, this may vary so please review your copy of your agreement.</p>',
	            },
	            {
		            title: 'I want to settle my agreement early. How do I do this?',
		            copy: '<p>You can settle your agreement at any time by contacting Unity Auto Finance Ltd Customer Service Team to request a settlement quote. Settlement figures are valid for 28 days and, depending upon the number of payments already made, you may be entitled to a rebate of interest paid. The formula used to calculate the settlement figure is set out in the Consumer Credit Act and may result in the interest rebate being less than you expect.</p>',
	            },
	            {
		            title: 'Can I make a part payment to reduce the balance owing on my agreement?',
		            copy: '<p>You may make a lump sum payment to reduce the balance outstanding on your agreement. Any payment you make will be applied to reduce the remaining monthly payments due as part of your Agreement. For more information about how to make a part payment, please contact our Customer Service Team.</p>',
	            },
	            {
		            title: 'The Vehicle is not for me. I financed it for a family member or a friend who lives at a different address. Can I change the details on the agreement?',
		            copy: '<p>No, it is a breach of the terms and conditions to finance a vehicle for another person or to transfer title to a third party before the finance is settled. The vehicle should be under your control and be in your possession at your home address and the monthly payments remain your responsibility.</p>',
	            },
	            {
		            title: 'Can I sell my vehicle?',
		            copy: '<p>You may only sell your vehicle once you have settled your finance Agreement in full.</p>',
	            },
	            {
		            title: 'If I have a problem with my vehicle. Can you help?',
		            copy: "<p>We hope this never happens but if you do encounter a problem with your vehicle, you should contact your supplying dealer in the first instance but remember we are here also to assist.</p>",
	            },
	            {
		            title: 'I am having difficulty making my monthly payment. What should I do?',
		            copy: '<p>For any problems making payment please contact our Customer Care team on 0330 912 1002 or email <a href="mailto:assistance@unityauto.finance">assistance@unityauto.finance</a></p>',
	            },
	            {
		            title: 'I am unhappy with the way Unity Auto Finance Ltd is handling my agreement. What should I do?',
		            copy: "<p>We hope you do not have any need to complain but if, for any reason, our service falls below your expectations please address your complaint to our Complaints Team at our head office. Unity Auto Finance Ltd subscribes to the FLA (Finance & Leasing Association) lending code. If you are unhappy with the way your complaint has been resolved, you can refer the matter to the FLA under their Code of Practice or to the Financial Ombudsman Service – whose details would be provided in our final response letter. For a copy of our Complaints Procedure please contact our Customer Service Manager.</p>",
	            },
	            {
		            title: 'What is the best way to get in touch at application stage?',
		            copy: '<p>Call 0330 912 1002 or email <a href="mailto:uaf.uw@unityauto.finance">uaf.uw@unityauto.finance</a></p>',
	            },
	            {
		            title: 'Once I take delivery of the vehicle who do I contact for general enquiries and settlements?',
		            copy: '<p>Please contact our Customer Care team on 0330 912 1002 or email <a href="mailto:assistance@unityauto.finance">assistance@unityauto.finance</a></p>',
	            },
            ]
        }
    },

    mounted() {
        this.$emit('update-banner-title', {
            title: 'Frequently asked questions',
            subTitle: ''
        })
    }
}
</script>

<style scoped>

</style>
