<template>
    <section class="bg-gray-50 py-12 md:py-24 px-8 sm:px-6">
        <div class="flex-col justify-between max-w-screen-xl mx-auto text-center">
            <h4 class="hdr-lead mb-3">How It Works</h4>
            <h2 class="font-extrabold mb-8 lg:w-1/2 mx-auto">We mean it when we say we’re a more ethical car finance lender</h2>
            <p class="lg:w-1/2 mx-auto text-xl font-light">We’re passionate about the environment and we know we need to plant a lot of trees to truly make a big difference - so Unity are involved in a number of environmental and social initiatives.</p>

            <div class="grid md:grid-cols-2 lg:grid-cols-3 space-x-4 xl:space-x-8">
                <div v-for="(item, i) in items" :key="i" class="px-8 flex flex-wrap content-start space-y-8"  data-aos="fade-up" data-aos-duration="500" :data-aos-offset="item.offset">
                    <img :src="require(`@/assets/images/${item.img}`)" :alt="item.title" class="shrink h-52 mx-auto" />
                    <div class="w-full">
                        <h4 class="hdr-lead mb-3">{{ item.title }}</h4>
                        <p>{{ item.desc }}</p>
                    </div>
                </div>
            </div>

            <!--<div class="flex justify-center items-center py-8 mt-8">-->
            <!--    <Button @click.native="$emit('apply-url')" theme="primaryGreen" title="Get An Instant Secure Quote">-->
            <!--        Get an instant quote-->
            <!--    </Button>-->
            <!--</div>-->
        </div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
export default {
    name: "HowItWorks",
    components: {Button},
    data() {
        return {
            items: [
                {
                    title: 'One car - one tree',
                    desc: 'We plant native trees to capture carbon',
                    img: 'car-tree.svg',
                    offset: '50'
                },
                // {
                //     title: 'Discount for EVs',
                //     desc: '1% APR discount for any battery electric vehicle (BEV) financed',
                //     img: 'electric-car.svg',
                //     offset: '120'
                // },
                {
                    title: 'Certified Carbon Neutral',
                    desc: '171.83tCO2e - less than the average carbon footprint of 14 people',
                    img: 'carbon-neutral.svg',
                    offset: '190'
                },
                {
                    title: 'Fleet of 40+ electric cars',
                    desc: 'Keeping the air clean in our community',
                    img: 'car-fleet.svg',
                    offset: '230'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
