<template>
    <section class="hero bg-unity-bluegreen w-full relative" :class="{'banner-home': $route.name === 'home' || $route.name === 'about'}">
        <div class="max-w-screen-xl mx-auto px-8 sm:px-16 banner-content py-16 relative">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl" :class="$route.name === 'home' ? 'mb-0' : 'mb-4'">
                <span class="block xl:inline">{{ title }}</span>
            </h1>
            <p v-if="subTitle !== null && $route.name === 'home' || $route.name === 'car-finance' || $route.name === 'eligibility-checker' || $route.name === 'about'" class="mt-3 max-w-md text-base text-gray-100 sm:text-xl md:mt-5 md:text-2xl md:max-w-3xl">
                {{ subTitle }}
            </p>

            <div class="flex-none md:flex gap-4">

                <!--<div class="flex items-center mt-8">-->
                <!--    <Button :href="applyUrl" theme="primary" title="Get An Instant Secure Quote">-->
                <!--        <template v-if="$route.name === 'eligibility-checker'">-->
                <!--            Check Eligibility-->
                <!--        </template>-->
                <!--        <template v-else>-->
                <!--            Get an instant quote-->
                <!--        </template>-->
                <!--    </Button>-->
                <!--</div>-->

                <div class="mt-8 text-lg flex">
                    <div class="flex items-start md:items-center">
                        <div class="rounded-full bg-unity-red mr-4 p-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                            </svg>
                        </div>
                    </div>
                    <div>
	                      <div class="block">Rates from 18.9% APR. Representative APR 30.9%</div>
												<div class="text-xl text-unity-red font-bold w-full md:w-1/2">We finance cars that are up to <strong>14 years old</strong> and have <strong>up to 100,000 miles</strong> at the time of inception.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape shape--circle" data-aos="fade-left" data-aos-delay="250"></div>

    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
import Modal from "@/components/Unity/Modal";

export default {
    name: "Banner",
    components: {
        Button,
        Modal
    },
    props: {
        title: {
            type: String,
            default: "Unity Auto Finance",
        },
        subTitle: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            applyUrl: process.env.VUE_APP_APPLY,
            isModalAprVisible: false,
        }
    },
    methods: {
        showAprModal() {
            this.isModalAprVisible = true;
        },

        closeAprModal() {
            this.isModalAprVisible = false;
        },
    }
}
</script>

<style lang="scss">
    .hero {
        @apply relative text-white pt-32;
        min-height: 530px;

        &.banner-home {
            background: url('../assets/images/banner-home.jpg') no-repeat;
            background-position: center -100px;
            background-size: cover;
            min-height: 550px;

            @media(min-width: 2000px) {
                background-position: center 0;
                min-height: 600px;

                &:after {
                    height: 272px;
                }
            }

            @media(max-width: 1000px) {
                background-position: center 0;
            }
        }

        &:after {
            bottom: -1px;
            content: '';
            height: 212px;
            background: url('../assets/images/banner-overlay.svg') no-repeat;
            transition: 1s;

            @apply absolute w-full left-0 bg-cover;

            @media(min-width: 2000px) {
                height: 292px;
            }

            @media (max-width: 1240px) {
                height: 120px;
                transform: scaleX(1);
            }

            @media (max-width: 860px) {
                height: 90px;
                transform: scaleX(1);
            }

            @media (max-width: 600px) {
                height: 70px;
                transform: scaleX(1);
            }

            @media (max-width: 400px) {
                height: 40px;
                transform: scaleX(1);
            }
        }

        &:before {
            z-index: 0;
            content: '';
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0.6682423311121324) 0%, rgba(0,0,0,0) 100%);

            @apply top-0 left-0 h-full w-full absolute;
        }

        .banner-content {
            z-index: 3;

            @apply relative;

            h1, h2, h3 {
                @apply text-white;
            }
        }

        .logo-carbon {
            bottom: -5rem;
            left: 2rem;
        }
    }

    .home-page {
        .shape {
            width: 226px;
            height: 226px;
            position: absolute;
            right: 4rem;
            bottom: -16px * 9.5;
            background-image: url("~@/assets/images/arrow--left.svg");
            background-size: cover;
            background-position: center;
            z-index: 2;

            &--circle {
                width: 81px;
                height: 163px;
                top: 424px;
                bottom: auto;
                background-image: url("~@/assets/images/half-circle--yellow--left.svg");

                @media (max-width: 860px) {
                    width: 60px;
                    height: 120px;
                    top: 470px;
                }

                @media (max-width: 600px) {
                    width: 45px;
                    height: 90px;
                    top: 490px;
                    right: 20px;
                }
            }
        }
    }

    .inner-page {
        .shape {
            width: 226px;
            height: 226px;
            position: absolute;
            right: 4rem;
            bottom: -16px * 9.5;
            background-image: url("~@/assets/images/arrow--left.svg");
            background-size: cover;
            background-position: center;
            z-index: 2;

            &--circle {
                width: 61px;
                height: 123px;
                top: 411px;
                bottom: auto;
                background-image: url("~@/assets/images/half-circle--yellow--left.svg");

                @media (max-width: 860px) {
                    width: 60px;
                    height: 120px;
                    top: 361px;
                }

                @media (max-width: 600px) {
                    width: 45px;
                    height: 90px;
                    top: 383px;
                    right: 20px;
                }
            }
        }
    }
</style>
