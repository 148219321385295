<template>
    <section class="bg-gray-50 py-12 md:py-24 px-8 sm:px-6">
        <div class="flex-col justify-between max-w-screen-xl mx-auto text-center">
            <h4 class="hdr-lead mb-3">Pro's of pcp finance</h4>
            <h2 class="font-extrabold mb-16">Why choose PCP finance?</h2>

            <div class="grid md:grid-cols-2 lg:grid-cols-3 space-x-4 xl:space-x-8">
                <div v-for="(item, i) in items" :key="i" class="px-8 flex flex-wrap content-start space-y-8" data-aos="fade-up" data-aos-duration="500" :data-aos-offset="item.offset">
                    <img :src="require(`@/assets/images/${item.img}`)" :alt="item.title" class="shrink h-52 mx-auto" />
                    <div>
                        <h4 class="hdr-lead mb-3">{{ item.title }}</h4>
                        <p>{{ item.desc }}</p>
                    </div>
                </div>
            </div>

            <p class="mt-16 md:w-1/2 mx-auto">By choosing Personal Contract Purchase you’ll become part of the majority of UK motorists who use PCP finance as the most common way to buy a newer car due to affordable monthly payments.</p>

            <!--<div class="flex justify-center items-center py-8 mt-8">-->
            <!--    <Button @click.native="$emit('apply-url')" theme="primary" title="Get An Instant Secure Quote" class="mt-8">-->
            <!--        Get an instant quote-->
            <!--    </Button>-->
            <!--</div>-->
        </div>
    </section>
</template>

<script>
import Button from "@/components/Unity/Button";
export default {
    name: "WhyPcp",
    components: {Button},
    data() {
        return {
            items: [
                {
                    title: 'Lower monthly payments',
                    desc: 'Lower monthly payments than an equivalent hire purchase or loan',
                    img: 'lower-monthly-payments.svg',
                    offset: '50'
                },
                {
                    title: 'Flexibility ',
                    desc: 'Three choices at the end of your agreement.',
                    img: 'flexibilty.svg',
                    offset: '140'
                },
                {
                    title: 'Guaranteed Minimum Future Value',
                    desc: 'No worries about the price falling for trade-in.',
                    img: 'future-value.svg',
                    offset: '230'
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>
