<template>
    <footer class="bg-unity-dark-grey py-20 text-gray-400 text-base relative">


        <div class="max-w-screen-xl mx-auto px-8 sm:px-6">

            <div class="md:flex md:justify-between">
                <div class="grid gap-8 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 w-full">
                    <div>
                        <strong>Unity Auto Finance</strong><br />
                        Unity House, Bird Hall Lane,<br />
                        Hercules Park, Stockport,<br />
                        England, SK3 0UX, GB
                    </div>
                    <div>
                        <strong>Talk to us</strong><br />
                        t. <a :href="`tel:${telephone}`" class="link text-unity-red hover:text-white" :title="telephone">{{ telephone }}</a><br /><br />
	                      <strong>Email:</strong><br />
	                      For all new enquiries please email: <a href="mailto:uaf.uw@unityauto.finance" class="link text-unity-red hover:text-white">uaf.uw@unityauto.finance</a> and if you are an existing customer please email: <a href="mailto:assistance@unityauto.finance" class="link text-unity-red hover:text-white">assistance@unityauto.finance</a>
                    </div>
                    <div>
                        <strong>Opening hours</strong><br />
	                    9am to 6pm Monday to Friday<br />
	                    (Closed on Bank Holidays)

                    </div>
                    <div class="social-media flex">
                        <ul class="flex space-x-4">
                            <li><a href="https://www.facebook.com/UnityAutoFinance" title="Facebook" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg></a></li>

                            <li><a href="https://www.linkedin.com/company/unityautofinance/?viewAsMember=true" title="LinkIn" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg></a></li>

                            <li><a href="https://www.instagram.com/unityautofinance/" title="LinkIn" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a></li>
                        </ul>
                        <img src="@/assets/images/carbon-neutral.png" alt="Carbon Neutral" class="logo-carbon w-20 h-20 ml-8 -mt-3" />
                    </div>
                </div>
            </div>
            <div class="my-8">
                <ul class="md:flex space-y-6 md:space-y-0 md:space-x-6">
                    <li><a href="./unity-privacy-v2-3.pdf" target="_blank" title="Privacy policy" class="link text-unity-red hover:text-white">Privacy</a></li>
                    <li><router-link :to="{name: 'complaints'}" title="Complaints" class="link text-unity-red hover:text-white">Complaints Policy</router-link></li>
                    <li><router-link :to="{name: 'complaints-handling-procedure'}" title="Complaints Handling Procedure" class="link text-unity-red hover:text-white">Complaints Handling Procedure</router-link></li>
                    <li><router-link :to="{name: 'terms'}" title="Terms & Conditions" class="link text-unity-red hover:text-white">Terms & Conditions</router-link></li>
                </ul>
            </div>
            <div class="my-8">
                <p class="text-gray-400">Unity Auto Finance Ltd is authorised and regulated by the Financial Conduct Authority (Firm reference number - 630103). Unity Auto Finance Ltd is a company registered in England & Wales with a company number 04308759, our VAT registration number is 322 3523 42.</p>

                <p class="text-gray-400">As members of the Finance & Leasing Association (FLA), we are committed to high standards and transparency, we follow the FLA lending code, a copy of which can be <a href="https://www.fla.org.uk/business-information/documents/lending-code-2021/" title="" target="_blank" class="link text-unity-red hover:text-white">found here</a>.</p>

                <p class="text-gray-400">&copy; Unity Auto Finance {{ new Date().getFullYear() }} | All rights reserved</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            telephone: '0161 5246188'
        }
    }
}
</script>

<style scoped lang="scss">
    .social-media {
        li {
            @apply rounded-full p-3 border-2 border-unity-red w-12 h-12 left-0 top-0 flex justify-center cursor-pointer shadow-md;

            &:hover {
                @apply bg-unity-red;
            }

            svg {
                @apply h-full relative;

                fill: #fff;
            }
        }
    }

    p {
        @apply mb-4;
    }

    strong {
        color: #fff;
    }
</style>
