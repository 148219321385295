<template>
    <main class="products">
        <section class="grid lg:grid-cols-2">
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mt-8 mb-3">Car finance explained</h4>
                    <h2 class="font-extrabold mb-8">Simply… Unity provides car finance agreements</h2>
                    <p>This is where things get a little technical, but we’ve done our best to keep things clear and simple. Putting it simply…Unity provides motor vehicle finance to suitable customers through regulated hire purchase and personal contract purchase (PCP) agreements.</p>

                    <p>Under a HP (hire purchase) agreement you usually - but not always - pay a deposit at the outset at the start of the agreement. You then pay the rest of the value of the car in instalments, over a period of between 3 and 5 years. You hire the vehicle until you make your final payment, after which you own it. PCP operates in a similar way, but offsets a certain amount of the car's value until the end of the agreement, which brings the monthly payments down compared to an equivalent HP agreement. Find out more about PCP here.</p>

                    <p>HP agreements are usually for a fixed monthly cost, meaning that the APR (Annual Percentage Rate) is set before the contract begins. The finance agreement is secured against the vehicle and this provides the lender with some flexibility regarding the terms they can offer.</p>

                </div>
            </div>
            <div>
                <img src="@/assets/images/iStock-1319763191.jpg" alt="" class="object-cover w-full"/>
            </div>
        </section>

        <OurProducts @apply-url="$emit('apply-url')" />

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey">
            <div>
                <img src="@/assets/images/istockphoto-1322477142-1024x1024.jpg" alt="" class="object-cover"/>
            </div>
            <div class="flex items-center p-8 lg:px-16">
                <div>
                    <h4 class="hdr-lead mt-8 mb-3">HP explained</h4>
                    <h2 class="font-extrabold mb-8 text-white">The amount you wish to borrow is based on the value of the vehicle</h2>
                    <p class="text-white">The amount you wish to borrow is based on the value of the vehicle you want to buy, minus any deposit you are able to provide. A deposit can be in the form of cash or a part exchange vehicle.</p>

                    <p class="text-white">At the end of the agreement, once all of the monthly payments have been made, you then have the choice whether or not to pay the final ‘option to purchase fee’. Paying this fee means that you become owner of the vehicle.</p>

                    <p class="text-white">You also have options to settle the finance agreement part way through the term of the agreement, your finance provider can give you specific details at any time.</p>

                    <Button @click.native="$emit('apply-url')" theme="outlineRed" title="" class="mt-8">Apply today</Button>
                </div>
            </div>
        </section>



        <information />
        <CallBackForm />

    </main>
</template>

<script>
import Button from "@/components/Unity/Button";
import Information from "@/components/Unity/Information";
import CallBackForm from "@/components/CallBackForm";
import OurProducts from "@/components/Unity/OurProducts";

export default {
    name: "products",
    components: {
        OurProducts,
        CallBackForm,
        Button,
        Information
    },
    metaInfo: {
        title: 'Products',
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'We’re here to help you whenever you need us',
            subTitle: ''
        })
    }
}
</script>

<style scoped lang="scss">
.section-team {
    &:before {
        @apply absolute top-0 left-0 w-full;

        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0.7682423311121324) 0%, rgba(0,0,0,0) 100%);
        content: '';
        height: 100vh;
        z-index: 1;
    }
}
</style>
